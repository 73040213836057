.tree-select {
    position: relative;
    /* width: 227.43px; */
    width: 100%;
    height: 44px;
    font-size: 14px;
    /* Basic/White */
    background: inherit;
    /* background: #ffffff; */
    /* Light Grey 02 */
}

.tree-select > input {
    position: relative;
    width: 100%;
    height: 100%;
    padding-left: 10px;
    border: 1px solid var(--light-gray);
    border-radius: 8px;
    cursor: pointer;
}

.tree-select > .input-arrow {
    position: absolute;
    width: 16px;
    height: 16px;
    right: 10px;
    top: 35%;
    color: var(--dark-4);
}

/* .tree-select:focus > i {
    background-color: red;
} */

.tree-select .dropdown-list {
    width: 100%;
    box-sizing: border-box;
    transition: all 0.15s cubic-bezier(0.25, 0, 0.25, 1.75), opacity 0.1s linear;

    background: #ffffff;
    /* Elevation / Level 3 */
    box-shadow: 0px 10px 22px rgba(45, 77, 108, 0.15);
    border-radius: 8px;

    margin-top: 4px;
    padding: 3px 0;
    opacity: 0;
    display: none;
    overflow: hidden;
    position: absolute;
    top: 100%;
    left: 0;
    z-index: 999;
    max-height: 250px;
    overflow: auto;
    border: 1px solid #ddd;
}

.tree-select > .dropdown-list.show {
    opacity: 1;
    display: block;

    -webkit-animation: fadeInDown 0.3s;
    animation: fadeInDown 0.3s;
    animation-fill-mode: forwards;
}

.tree-select > .dropdown-list.pointerEventNone {
    pointer-events: none;
}

.tree-select > .dropdown-list ul {
    width: 100%;
    padding: 0;
    position: relative;
    padding: 2px;
    cursor: pointer;
    /* padding-left: 10px; */
    list-style-type: none;
}

.tree-select > .dropdown-list ul > li {
    padding: 7.5px 16px;
    border-radius: 8px;
}

.tree-select > .dropdown-list > ul > .option:hover,
.tree-select > .dropdown-list > ul > .option:focus {
    background-color: #f1f5f9;
}

.tree-select > .dropdown-list > ul > .option.unselectable:hover,
.tree-select > .dropdown-list > ul > .option.unselectable:focus {
    background-color: white;
}

.tree-select .option.selected {
    font-weight: 600;
    color: var(--dark-2);
}

.tree-select .option.selected:focus {
    background: #f6f6f6;
}

.tree-select input[type='search']::-webkit-search-decoration,
.tree-select input[type='search']::-webkit-search-cancel-button,
.tree-select input[type='search']::-webkit-search-results-button,
.tree-select input[type='search']::-webkit-search-results-decoration {
    -webkit-appearance: none;
}

.tree-select .tree-arrow {
    position: relative;
    width: 16px;
    height: 16px;
    left: 0px;
    top: 0;
    color: var(--dark-4);
}

.tree-select .unselectable {
    -webkit-user-select: none;
    -webkit-touch-callout: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

/* /////////////// */
/* dark theme */
/* /////////////// */

.tree-select .dropdown-list {
    color: var(--dark-4);
}

.tree-loader.lds-spinner.show {
    opacity: 1;
    transition: 0.1s;
}

.tree-loader.lds-spinner {
    display: inline-block;
    position: absolute;
    width: 80px;
    height: 80px;
    zoom: 30%;
    top: 30px;
    right: 15%;
    opacity: 0;
    transition: 0.1s;
}

.lds-spinner {
    display: inline-block;
    position: absolute;
    width: 80px;
    height: 80px;
    zoom: 30%;
    top: 30px;
    right: 15%;
    opacity: 0;
    transition: 0.1s;
}
.lds-spinner div {
    transform-origin: 40px 40px;
    animation: lds-spinner 1.2s linear infinite;
}
.lds-spinner div:after {
    content: ' ';
    display: block;
    position: absolute;
    top: 3px;
    left: 37px;
    width: 6px;
    height: 18px;
    border-radius: 20%;
    background: rgb(120, 119, 119);
}
.lds-spinner div:nth-child(1) {
    transform: rotate(0deg);
    animation-delay: -1.1s;
}
.lds-spinner div:nth-child(2) {
    transform: rotate(30deg);
    animation-delay: -1s;
}
.lds-spinner div:nth-child(3) {
    transform: rotate(60deg);
    animation-delay: -0.9s;
}
.lds-spinner div:nth-child(4) {
    transform: rotate(90deg);
    animation-delay: -0.8s;
}
.lds-spinner div:nth-child(5) {
    transform: rotate(120deg);
    animation-delay: -0.7s;
}
.lds-spinner div:nth-child(6) {
    transform: rotate(150deg);
    animation-delay: -0.6s;
}
.lds-spinner div:nth-child(7) {
    transform: rotate(180deg);
    animation-delay: -0.5s;
}
.lds-spinner div:nth-child(8) {
    transform: rotate(210deg);
    animation-delay: -0.4s;
}
.lds-spinner div:nth-child(9) {
    transform: rotate(240deg);
    animation-delay: -0.3s;
}
.lds-spinner div:nth-child(10) {
    transform: rotate(270deg);
    animation-delay: -0.2s;
}
.lds-spinner div:nth-child(11) {
    transform: rotate(300deg);
    animation-delay: -0.1s;
}
.lds-spinner div:nth-child(12) {
    transform: rotate(330deg);
    animation-delay: 0s;
}

.dark .tree-select > input,
.dark .tree-select .dropdown-list {
    border: 1px solid #7a8599;
    background: var(--dark-5);
    color: white;
}

.dark .tree-select .tree-arrow,
.dark .tree-select .option.selected {
    color: white;
}

.dark .tree-select > .input-arrow {
    color: white;
}

.dark .tree-select > .dropdown-list > ul > .option:hover,
.dark .tree-select > .dropdown-list > ul > .option:focus {
    background-color: var(--light-gray-4);
}

.dark .tree-select > .dropdown-list > ul > .option.unselectable:hover,
.dark .tree-select > .dropdown-list > ul > .option.unselectable:focus {
    background-color: var(--light-gray-4);
}

@keyframes lds-spinner {
    0% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}
