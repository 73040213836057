.customer-profile-container > .row > .title {
    font-weight: 900;
    font-size: 24px;
    line-height: 36px;
    color: var(--dark-4);
    padding-bottom: 16px;
}

.customer-profile-container .search-container {
    display: flex;
    flex-wrap: wrap;
}

/* /////////////// */
/* dark theme */
/* /////////////// */

.dark .customer-profile-container > .row > .title,
.dark .customer-profile-container .input-container {
    color: white;
}

@media screen and (max-width: 600px) {
    .customer-profile-container .search-container {
        flex-direction: column;
        align-items: baseline;
        gap: 3px;
    }
}
