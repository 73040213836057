.paymentSetting .custom-button-group {
    display: flex;
    /* gap: 20px; */
    flex-wrap: wrap;
    flex-direction: column;
}

.paymentSetting .custom-button-group > .item{
    display: flex;
    flex-wrap: nowrap;
    gap: 8px;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 0px;
    padding-right: 5px;
    align-items: center;
}

.custom-button-group {
    display: flex;
    /* gap: 20px; */
    flex-wrap: wrap;
}

.custom-button-group > .item {
    display: flex;
    flex-wrap: wrap;
    gap: 18px;
    padding-top: 10px;
    padding-bottom: 10px;
    align-items: center;
}

.custom-button-group > .item > .title {
    width: max-content;
}

.custom-button-group > .item > .label {
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    color: #0f172a;
}

.custom-button-group > .item > input[type='radio'] {
    /* Add if not using autoprefixer */
    -webkit-appearance: none;
    /* Remove most all native input styles */
    appearance: none;
    /* For iOS < 15 */
    /* background-color: red; */
    /* Not removed via appearance */
    /* font: inherit; */
    /* color: currentColor; */
    width: 20px;
    height: 20px;
    border: 1px solid #7a8599;
    border-radius: 50%;
    transform: translateY(0.075em);

    display: grid;
    place-content: center;
    cursor: pointer;
}

.custom-button-group > .item > input[type='radio']::before {
    content: '';
    width: 12px;
    height: 12px;
    border-radius: 50%;
    transform: scale(0);
    transition: 120ms transform ease-in-out;
}

.custom-button-group > .item > input[type='radio']:checked {
    transform: scale(1);
    background-color: #3268f6;

    border: 1px solid #3268f6;
    transform: translateY(0.075em);
}

.custom-button-group > .item > input[type='radio']:checked::before {
    transform: scale(1);
    background-color: white;
}

.dark .custom-button-group {
    color: white;
}
