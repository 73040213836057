	/*
  	Flaticon icon font: Flaticon
  	Creation date: 29/11/2020 06:42
  	*/

    @font-face {
      font-family: "Flaticon";
      src: url("./Flaticon.eot");
      src: url("./Flaticon.eot?#iefix") format("embedded-opentype"),
      url("./Flaticon.woff2") format("woff2"),
      url("./Flaticon.woff") format("woff"),
      url("./Flaticon.ttf") format("truetype"),
      url("./Flaticon.svg#Flaticon") format("svg");
      font-weight: normal;
      font-style: normal;
    }

    @media screen and (-webkit-min-device-pixel-ratio:0) {
      @font-face {
        font-family: "Flaticon";
        src: url("./Flaticon.svg#Flaticon") format("svg");
      }
    }

    [class^="flaticon-"]:before, [class*=" flaticon-"]:before,
    [class^="flaticon-"]:after, [class*=" flaticon-"]:after {   
      font-family: Flaticon;
      speak: none;
      font-style: normal;
      font-weight: normal;
      font-variant: normal;
      text-transform: none;
      line-height: 1;

      /* Better Font Rendering =========== */
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
    }

    .flaticon-house-2:before { content: "\f100"; }
    .flaticon-building:before { content: "\f101"; }
    .flaticon-house:before { content: "\f102"; }
    .flaticon-house-1:before { content: "\f103"; }
    .flaticon-house-3:before { content: "\f104"; }
    .flaticon-house-4:before { content: "\f105"; }