#siderbar-col {
    width: 65px;
    height: auto;
    /* min-height: calc(100vh - 100px); */
    /* height: 100%; */
}

.side-menu-page-container {
    position: relative;
    width: 100%;
    height: calc(100vh - 75px);
    /* max-height: 100vh; */
    overflow: auto;
    overflow-x: hidden;
}

.side-menu-page-container > .page-content {
    min-height: 85vh;
}

.left-menu-containter {
    position: absolute;
    z-index: 900;
    /* left: 10px; */
    width: auto;
    /* height: 100%; */
    /* min-height: 100vh; */
    min-height: calc(100vh - 75px);
    background-color: var(--light-gray-3);
    /* padding-top: 20px;
  padding-bottom: 20px; */
}

.side-nav {
    height: 100%;
    width: 60px;
    background-color: var(--light-gray-3);
    /* overflow-x: hidden; */
    /* padding-top: 60px; */
    transition: 0.15s ease-in-out;
    text-align: left;
    /* border-radius: 20px; */
    cursor: pointer;
}

/* ON HOVER */
.side-nav.show {
    width: 240px;
}

.side-nav .item {
    position: relative;
    display: flex;
    align-items: center;
    height: 45px;
    width: 100%;
    left: 0px;
    /* top: 17px; */
    border-radius: 0px;
    transition: 0.1s ease-in;
    padding-left: 18px;
}

.side-nav.show .item {
    width: 240px;
}

.side-nav.show .item > .sub-menu > .item:hover {
    /* background-color: #f8f9fc;
    color: black; */
    opacity: 0.7;
}

.side-nav .item > .item-content {
    display: flex;
    width: 100%;
    height: 21px;
}

.side-nav .item.active > .item-content {
    border-right: 3px solid var(--dark-4);
}

.side-nav .item img {
    width: 25px;
    height: 25px;
}

.side-nav .item:hover img {
    transform: scale(1.2);
    transition: 0.3s;
}

.side-nav.show .item.active {
    background: var(--dark-4);
}

.side-nav:hover .item.active > .item-content > .link {
    color: white;
}

.side-nav .item img {
    /* #2B2E38 */
    filter: invert(16%) sepia(25%) saturate(367%) hue-rotate(189deg)
        brightness(98%) contrast(95%);
}

.side-nav.show .item.active img {
    /* #white */
    filter: invert(100%) sepia(0%) saturate(7435%) hue-rotate(140deg)
        brightness(104%) contrast(104%);
}

.side-nav.show .item:hover > .item-content {
    opacity: 0.8;
}

.side-nav.show .item:hover > .item-content > .link {
    /* color: var(--light-gray-4)!important; */
}


/* The navigation menu links */
.side-nav .link {
    opacity: 0;
    padding-left: 17.71px;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 21px;
    /* color: #7a8599; */
    color: var(--dark-4);
    transition: 0.3s ease-out;
    overflow: hidden;
}


/* hover display nav label */
.side-nav.show .link {
    opacity: 1;
    /* transition: 0.6s; */
}

/* .side-nav .item.active .link {
    color: #212533;
} */

/* dark mode */
.dark .side-nav {
    background-color: #1C1C24;
}

.dark .side-nav .link {
    color: var(--light-gray-4);
}

.dark .side-nav.show .item.active .link {
    color: var(--dark-2)!important;
}

.dark .left-menu-containter {
    background-color: #1C1C24;
}

.dark .side-nav .item.active > .item-content {
    border-right: 3px solid #05cd99;
}

.dark .side-nav:hover .item.active {
    background: #05cd99;
}

.dark .side-nav .item img {
    /* #7A8599 */
    filter: invert(53%) sepia(8%) saturate(824%) hue-rotate(180deg)
        brightness(96%) contrast(88%);
}

.dark .side-nav .item.active img {
    /* #05CD99 */
    filter: invert(77%) sepia(63%) saturate(6006%) hue-rotate(126deg)
        brightness(103%) contrast(96%);
}

.dark .side-nav:hover .item.active img {
    /* #212533 */
    filter: invert(15%) sepia(15%) saturate(849%) hue-rotate(189deg)
        brightness(95%) contrast(101%);
}

@media only screen and (max-width: 600px) {
    .left-menu-containter {
        display: none;
    }
}

/* width */
.side-nav::-webkit-scrollbar {
    width: 10px;
}

/* Track */
.side-nav::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px grey;
    border-radius: 10px;
}

/* Handle */
.side-nav::-webkit-scrollbar-thumb {
    background-color: #d6dee1;
    border-radius: 20px;
    border: 3px solid transparent;
    background-clip: content-box;
}

/* Handle on hover */
.side-nav::-webkit-scrollbar-thumb:hover {
    /* background: #b30000;  */
}

.sidebar {
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 260px;
    background: #11101d;
    z-index: 100;
    transition: all 0.5s ease;
}

.side-nav .item {
    position: relative;
    list-style: none;
    transition: all 0.2s ease;
}
.side-nav .item li:hover {
    background: #1d1b31;
}


/* sub menu drop down */
.side-nav .item > .sub-menu {
    position: absolute;
    width: 100%;
    height: auto;
    left: 60%;
    top: -10px;
    margin-top: 0;
    border-radius: 6px;
    opacity: 0;
    display: block;
    pointer-events: none;
    transition: 0s;
    box-shadow: 0 0.15rem 1.75rem 0 rgb(58 59 69 / 15%);
    /* color: #858796; */
    color: var(--dark-4);
    text-align: center;
    list-style: none;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #e3e6f0;
    border-radius: 0.35rem;
    padding: 5px;
    z-index: 100;
    font-size: 16px;
}
.side-nav.show .item:hover > .sub-menu {
    top: 0;
    opacity: 1;
    pointer-events: auto;
    transition: all 0.5s ease;
}

.side-nav .item > .sub-menu > .item {
    padding: 10px;
    border-radius: 2px;
    position: relative;
    width: 100%;
    height: 35px;
}

.side-nav .item > .sub-menu > .item > .link {
    /* color: #858796; */
    color: var(--dark-4);
}

.side-nav .item > .sub-menu > .item.active {
    background: var(--dark-4);
    color: white;
}

.side-nav.show .item > .sub-menu > .item:hover {
    /* background-color: #f8f9fc;
    color: black; */
    opacity: 0.7;
}


.dark .side-nav .item > .sub-menu {
    background-color: #1C1C24;
    color: var(--light-gray-4);
    border: 1px solid #212533;
    box-shadow:none;
 }