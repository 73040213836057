.loader-container {
    position: fixed;
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgb(172, 169, 169,0.5);
    z-index: 9999;
    opacity: 0.5;
    transition: 0.3s;
}

.dark.loader-container {
    background: rgba(47, 41, 41, 0.6);
}

.loader-container.active {
    display: flex;
}

.loader-container.close {
    animation: fadein 0.3s forwards;
}

@keyframes fadein {
    from { opacity: 1;}
    to   { opacity: 0; visibility: hidden;}
  }

/* .dark .loader-container {
    background: rgba(186, 29, 29, 0.5);
    transition: 0.1s;
} */

.lds-facebook {
    display: inline-block;
    position: relative;
    width: 80px;
    height: 80px;
}
.lds-facebook div {
    display: inline-block;
    position: absolute;
    left: 8px;
    width: 16px;
    background: #fff;
    animation: lds-facebook 1.2s cubic-bezier(0, 0.5, 0.5, 1) infinite;
}
.lds-facebook div:nth-child(1) {
    left: 8px;
    animation-delay: -0.24s;
}
.lds-facebook div:nth-child(2) {
    left: 32px;
    animation-delay: -0.12s;
}
.lds-facebook div:nth-child(3) {
    left: 56px;
    animation-delay: 0;
}
@keyframes lds-facebook {
    0% {
        top: 8px;
        height: 64px;
    }
    50%,
    100% {
        top: 24px;
        height: 32px;
    }
}
