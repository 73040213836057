.form-input {
    /* display: flex; */
    /* width: min-content; */
    width: 100%;
}

.form-input > .input-error {
    color: red;
    font-size: 12px;
    padding-top: 3px;
}

.checkboxes-group-container .custom-checkbox {
    width: 20px;
    height: 20px;
    cursor: pointer;
}

.checkboxes-group-container .label {
    font-weight: 400;
    font-size: 16px;
    line-height: 21px;
    color: var(--dark-4);
    /* width: 95%; */
    /* width: max-content; */
}

.dark .checkboxes-group-container .label,
.dark .checkboxes-group-container .specify-input {
    color: white;
}
