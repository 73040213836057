.table-detail-container {
    width: 100%;
    /* max-width: 479px; */
    height: 100%;

    /* Light Grey 02 */
    background: var(--light-gray-3);
    border-radius: 12px;
    display: flex;
    /* justify-content: center; */
    /* padding: 20px; */
    flex-direction: column;
    align-items: center;
}

.table-detail-container > .title {
    width: 100%;
    height: auto;
    color: #ffffff;
    background-color: #3268f6;
    border-radius: 12px 12px 0px 0px;
    min-height: 45px;
    padding: 12px;

    font-weight: 600;
    font-size: 16px;
    line-height: 21px;
}
.table-detail-container > .row > .item > .borderBottom {
    border-bottom: 1px solid #cbd5e1;
    /* height: 15px; */
    width: 90%;
    position: absolute;
    left: 5%;
    /* padding-top: 15px; */
    bottom: 0;
}

.table-detail-container > .row > .item:last-child > .borderBottom {
    border-bottom: none;
}

.table-detail-container > .row > .item {
    position: relative;
    padding: 20px;
    padding-top: 20px;
    padding-bottom: 20px;
}

.table-detail-container > .row > .item:last-child {
    border: none;
}

.table-detail-container > .row > .item > .label {
    font-weight: 600;
    font-size: 16px;
    line-height: 21px;
    color: var(--light-gray-4);
}

.table-detail-container > .row > .item > .value {
    font-weight: 400;
    font-size: 18px;
    line-height: 35px;
    min-height: 35px;
    color: var(--dark-4);
}

.dark .table-detail-container > .row {
    background: var(--dark-5);
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
}

.dark .table-detail-container > .row > .item > .label {
    color: var(--light-gray-4);
}

.dark .table-detail-container > .row > .item > .value {
    color: var(--light-gray);
}

.dark .table-detail-container > .row > .item > .borderBottom {
    background: var(--light-gray-4);
}
