.sideFilter .daterangepicker.custom{
    height: 95px;
    display: flex;
    flex-direction: column;
    padding: 10px;
}

.sideFilter .daterangepicker.custom input{
    text-align: center;
}

