/* Top Nav */
.top-nav-container {
    background-color: var(--light-gray-2);
    width: 100%;
    height: auto;

    display: flex;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
    overflow-x: auto;
    overflow-y: hidden;

}


.top-menu-page-container .page-content {
    min-height: 78vh;
}

.dark .top-nav-container {
    background-color: #212533;
}

.top-nav-navbar {
    /* background: #33363D; */
    /* display: inline-block;
  padding: 0 15px;
  position: relative; */
    padding-left: 0rem;
    margin: 0;
    /* width: 100%; */
    /* overflow: auto; */
 
}
.top-nav-navbar li {
    display: table-cell;
    padding: 5px;
    min-width: 75px;
    text-align: center;
    /* background-color: red; */
    /* width: 76px; */
    width: auto;
    height: 100%;
    min-height: 66px;
    padding-left: 17px;
    padding-right: 17px;
    padding-bottom: 16px;
    padding-top: 16px;
}

.top-nav-navbar li.active {
    background-color: var(--light-gray-3);
}

.dark .top-nav-navbar li.active {
    background-color: #05cd99;
}

.top-nav-navbar > li > .item {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    height: 100%;
    width: 100%;
    cursor: pointer;
    /* color: #7a8599; */
    color: var(--dark-4);
    font-size: 14px;
    font-weight: 400;
    line-height: 21px;
    letter-spacing: 0em;
    position: relative;
    font-size: 16px;
}

.dark .top-nav-navbar > li > .item {
    color: #7a8599;
}

.top-nav-navbar li.active .item {
    color: #212533;
}

/* fill color convertor https://codepen.io/sosuke/pen/Pjoqqp */
.top-nav-navbar .item img {
    width: 24px;
    height: 24px;
    padding-bottom: 1px;
    /* #7A8599 */
    filter: invert(58%) sepia(6%) saturate(1219%) hue-rotate(180deg)
        brightness(88%) contrast(85%);
}

.top-nav-navbar .item:hover img {
    transform: scale(1.1);
    transition: 0.3s;
}

.top-nav-navbar li.active .item img {
    /* #212533 */
    filter: invert(15%) sepia(15%) saturate(849%) hue-rotate(189deg)
        brightness(95%) contrast(101%);
}

.top-nav-navbar > .item:hover {
    opacity: 0.5;
    transition: 0.1s;
}

/* sub menu drop down */
.top-nav-navbar .item > .sub-menu {
    position: fixed;
    width: fit-content;
    height: auto;
    left: 30px;
    /* bottom: -30px; */
    top: 0;
    border-radius: 6px;
    opacity: 0;
    display: block;
    pointer-events: none;
    transition: 0s;
    box-shadow: 0 0.15rem 1.75rem 0 rgb(58 59 69 / 15%);
    color: #858796;
    list-style: none;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #e3e6f0;
    border-radius: 0.35rem;
    padding: 5px;
    z-index: 100;
    text-align: left;
    font-size: 14px;
}
.top-nav-navbar .item:hover > .sub-menu {
    /* top: 0; */
    opacity: 1;
    pointer-events: auto;
    transition: all 0.4s ease;
}

.top-nav-navbar .item > .sub-menu > .item {
    white-space: nowrap;
    border-radius: 2px;
    padding: 8px 10px;
    position: relative;
    width: auto;
    height: 35px;
    font-size: 16px;
}

/* .top-nav-navbar .item > .sub-menu > .item > .link {
    color: #858796;
} */

.top-nav-navbar .item > .sub-menu > .item.active {
    background: var(--dark-4);
    color: white;
}

.top-nav-navbar .item > .sub-menu > .item:hover {
    /* background-color: #f8f9fc;
    color: black; */
    opacity: 0.7;
}

.dark .top-nav-navbar .item > .sub-menu {
    background-color: #1C1C24;
    border: 1px solid #212533;
    box-shadow:none
}

.dark .top-nav-navbar .item > .sub-menu > .item{
    color: var(--light-gray-4);
}

.dark .top-nav-navbar .item > .sub-menu > .item.active {
    color: white;
}

@media only screen and (max-width: 600px) {
    .top-nav-container {
        display: none;
    }
}
