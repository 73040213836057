@import url('https://www.utrade.com.my/css/bootstrap.min.css');
@import url('https://www.utrade.com.my/css/FontAwesome/css/font-awesome.min.css');
@import url('https://www.utrade.com.my/css/FancyBox/jquery.fancybox.css');
@import url('https://www.utrade.com.my/css/modules/public/custom_style_nav.css');
@import url('https://www.utrade.com.my/css/modules/public/custom_style.css');

@import url('https://fonts.googleapis.com/css?family=Open+Sans:400,300,300italic,400italic,600,600italic,700,800,800italic,700italic');
@import url('https://fonts.googleapis.com/css?family=PT+Serif:400,400italic,700,700italic');
@import url('https://fonts.googleapis.com/css?family=Raleway:400,100,100italic,200,200italic,300,300italic,400italic,500,500italic,600,600italic,700,700italic,800,800italic,900');
@import url('https://fonts.googleapis.com/css?family=Roboto:400,100,100italic,300,300italic,400italic,500,500italic,700,700italic,900,900italic');

body{
    font-size: 14px !important;
}

section{
  padding: 0px 0px !important;
}

.modal {
    display: none;
    position: fixed; 
    padding-top: 20px;
    right:0; 
    top: 0;
    bottom: 0; 
    overflow: auto;
    background-color: rgb(0,0,0); 
    background-color: rgba(0,0,0,0.4); 
    z-index: 9999;
}

.modal-content {
  background-color: #fefefe;
  margin: 5% auto;
  padding: 20px;
  border: 1px solid #888;
  width: 80%; 
}

.close {
  color: #aaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
}

.close:hover,
.close:focus {
  color: black;
  text-decoration: none;
  cursor: pointer;
}

.span-bold {
font-weight: bold;
}

#main-navigation-wrapper .nav > .dropdown > a{
  font-size: 0.7em !important;
}