/**
* Template Name: Mamba - v4.7.0
* Template URL: https://bootstrapmade.com/mamba-one-page-bootstrap-template-free/
* Author: BootstrapMade.com
* License: https://bootstrapmade.com/license/
*/

/*--------------------------------------------------------------
# General
--------------------------------------------------------------*/

/* @import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;700&display=swap'); */

@import url('./fonts/Poppins/Poppins-font.css');

body {
    font-family: 'Poppins', sans-serif !important;
    color: #444;
    overflow: hidden;
}

a {
    color: #428bca;
    text-decoration: none;
}

a:hover {
    color: #9eccf4;
    text-decoration: none;
}

/* h1, h2, h3, h4, h5, h6, .font-primary {
  font-family: "Raleway", sans-serif;
} */

/*--------------------------------------------------------------
# Back to top button
--------------------------------------------------------------*/
.back-to-top {
    position: fixed;
    visibility: hidden;
    opacity: 0;
    right: 15px;
    bottom: 15px;
    z-index: 99999;
    background: #428bca;
    width: 40px;
    height: 40px;
    border-radius: 4px;
    transition: all 0.4s;
}
.back-to-top i {
    font-size: 24px;
    color: #fff;
    line-height: 0;
}
.back-to-top:hover {
    background: #629fd3;
    color: #fff;
}
.back-to-top.active {
    visibility: visible;
    opacity: 1;
}

/*--------------------------------------------------------------
# Disable aos animation delay on mobile devices
--------------------------------------------------------------*/
@media screen and (max-width: 768px) {
    [data-aos-delay] {
        transition-delay: 0 !important;
    }
}
/*--------------------------------------------------------------
# Top Bar
--------------------------------------------------------------*/
#topbar {
    background: #fff;
    border-bottom: 1px solid #eee;
    font-size: 15px;
    height: 40px;
    padding: 0;
}
#topbar .contact-info a {
    line-height: 0;
    color: #444;
    transition: 0.3s;
}
#topbar .contact-info a:hover {
    color: #428bca;
}
#topbar .contact-info i {
    color: #428bca;
    line-height: 0;
    margin-right: 5px;
}
#topbar .contact-info .phone-icon {
    margin-left: 15px;
}
#topbar .social-links a {
    color: #5c768d;
    padding: 4px 12px;
    display: inline-block;
    line-height: 1px;
    transition: 0.3s;
}
#topbar .social-links a:hover {
    color: #428bca;
}

/*--------------------------------------------------------------
# Header
--------------------------------------------------------------*/
#header {
    height: 70px;
    background: #fff;
    z-index: 997;
    box-shadow: 0px 5px 15px 0px rgba(0, 0, 0, 0.06);
}
#header .logo h1 {
    font-size: 28px;
    margin: 0;
    padding: 10px 0;
    line-height: 1;
    font-weight: 400;
    letter-spacing: 3px;
    text-transform: uppercase;
}
#header .logo h1 a,
#header .logo h1 a:hover {
    color: #1c5c93;
    text-decoration: none;
}
#header .logo img {
    padding: 0;
    margin: 0;
    max-height: 40px;
}

.scrolled-offset {
    margin-top: 30px;
}

/*--------------------------------------------------------------
# Navigation Menu
--------------------------------------------------------------*/
/**
* Desktop Navigation 
*/
#header .navbar {
    padding: 0;
}
#header .navbar ul {
    margin: 0;
    padding: 0;
    display: flex;
    list-style: none;
    align-items: center;
}
#header .navbar li {
    position: relative;
}
#header .navbar a,
.navbar a:focus {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 0 10px 30px;
    font-family: 'Open Sans', sans-serif;
    font-size: 15px;
    color: #5c768d;
    white-space: nowrap;
    transition: 0.3s;
}
#header .navbar a i,
.navbar a:focus i {
    font-size: 12px;
    line-height: 0;
    margin-left: 5px;
}
#header .navbar a:hover,
.navbar .active,
.navbar .active:focus,
.navbar li:hover > a {
    color: #428bca;
}
#header .navbar .dropdown ul {
    display: block;
    position: absolute;
    left: 14px;
    top: 100%;
    margin: 0;
    padding: 10px 0;
    z-index: 99;
    opacity: 0;
    visibility: hidden;
    background: #fff;
    box-shadow: 0px 0px 30px rgba(127, 137, 161, 0.25);
    transition: 0.3s;
}
#header .navbar .dropdown ul li {
    min-width: 200px;
}
#header .navbar .dropdown ul a {
    padding: 10px 20px;
    text-transform: none;
}
#header .navbar .dropdown ul a i {
    font-size: 12px;
}
#header .navbar .dropdown ul a:hover,
.navbar .dropdown ul .active:hover,
.navbar .dropdown ul li:hover > a {
    color: #428bca;
}
#header .navbar .dropdown:hover > ul {
    opacity: 1;
    visibility: visible;
}
#header .navbar .dropdown .dropdown ul {
    top: 0;
    left: calc(100% - 30px);
    visibility: hidden;
}
#header .navbar .dropdown .dropdown:hover > ul {
    opacity: 1;
    top: 0;
    left: 100%;
    visibility: visible;
}
@media (max-width: 1366px) {
    #header .navbar .dropdown .dropdown ul {
        left: -90%;
    }
    #header .navbar .dropdown .dropdown:hover > ul {
        left: -100%;
    }
}

/**
* Mobile Navigation 
*/
.mobile-nav-toggle {
    color: #1f3548;
    font-size: 28px;
    cursor: pointer;
    display: none;
    line-height: 0;
    transition: 0.5s;
}
.mobile-nav-toggle.bi-x {
    color: #fff;
}

@media (max-width: 775px) {
    .mobile-nav-toggle {
        display: block;
    }

    .navbar ul {
        display: none;
    }
}
.navbar-mobile {
    position: fixed;
    overflow: hidden;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    background: rgba(31, 53, 72, 0.9);
    transition: 0.3s;
    z-index: 998;
}
.navbar-mobile .mobile-nav-toggle {
    position: absolute;
    top: 15px;
    right: 15px;
}
.navbar-mobile ul {
    display: block;
    position: absolute;
    top: 55px;
    right: 15px;
    bottom: 15px;
    left: 15px;
    padding: 10px 0;
    background-color: #fff;
    overflow-y: auto;
    transition: 0.3s;
}
.navbar-mobile a,
.navbar-mobile a:focus {
    padding: 10px 20px;
    font-size: 15px;
    color: #1f3548;
}
.navbar-mobile a:hover,
.navbar-mobile .active,
.navbar-mobile li:hover > a {
    color: #428bca;
}
.navbar-mobile .getstarted,
.navbar-mobile .getstarted:focus {
    margin: 15px;
}
.navbar-mobile .dropdown ul {
    position: static;
    display: none;
    margin: 10px 20px;
    padding: 10px 0;
    z-index: 99;
    opacity: 1;
    visibility: visible;
    background: #fff;
    box-shadow: 0px 0px 30px rgba(127, 137, 161, 0.25);
}
.navbar-mobile .dropdown ul li {
    min-width: 200px;
}
.navbar-mobile .dropdown ul a {
    padding: 10px 20px;
}
.navbar-mobile .dropdown ul a i {
    font-size: 12px;
}
.navbar-mobile .dropdown ul a:hover,
.navbar-mobile .dropdown ul .active:hover,
.navbar-mobile .dropdown ul li:hover > a {
    color: #428bca;
}
.navbar-mobile .dropdown > .dropdown-active {
    display: block;
}

/*--------------------------------------------------------------
# Hero Section
--------------------------------------------------------------*/
#hero {
    width: 100%;
    height: calc(100vh - 110px);
    padding: 0;
    overflow: hidden;
    background: #000;
}
#hero .carousel-item {
    width: 100%;
    height: calc(100vh - 110px);
    background-size: cover;
    background-position: top right;
    background-repeat: no-repeat;
    overflow: hidden;
}
#hero .carousel-item::before {
    content: '';
    background-color: rgba(13, 30, 45, 0.6);
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    overflow: hidden;
}
#hero .carousel-container {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    bottom: 0;
    top: 0;
    left: 0;
    right: 0;
    overflow: hidden;
}
#hero .carousel-content {
    text-align: left;
}
@media (max-width: 992px) {
    #hero,
    #hero .carousel-item {
        height: calc(100vh - 70px);
    }
    #hero .carousel-content.container {
        padding: 0 50px;
    }
}
#hero h2 {
    color: #fff;
    margin-bottom: 30px;
    font-size: 48px;
    font-weight: 900;
}
#hero p {
    width: 80%;
    -webkit-animation-delay: 0.4s;
    animation-delay: 0.4s;
    color: #fff;
}
#hero .carousel-inner .carousel-item {
    transition-property: opacity;
    background-position: center top;
}
#hero .carousel-inner .carousel-item,
#hero .carousel-inner .active.carousel-item-start,
#hero .carousel-inner .active.carousel-item-end {
    opacity: 0;
}
#hero .carousel-inner .active,
#hero .carousel-inner .carousel-item-next.carousel-item-start,
#hero .carousel-inner .carousel-item-prev.carousel-item-end {
    opacity: 1;
    transition: 0.5s;
}
#hero .carousel-inner .carousel-item-next,
#hero .carousel-inner .carousel-item-prev,
#hero .carousel-inner .active.carousel-item-start,
#hero .carousel-inner .active.carousel-item-end {
    left: 0;
    transform: translate3d(0, 0, 0);
}
#hero .carousel-control-prev,
#hero .carousel-control-next {
    width: 10%;
}
#hero .carousel-control-next-icon,
#hero .carousel-control-prev-icon {
    background: none;
    font-size: 48px;
    line-height: 1;
    width: auto;
    height: auto;
}
#hero .carousel-indicators li {
    cursor: pointer;
}
#hero .btn-get-started {
    font-family: 'Raleway', sans-serif;
    font-weight: 500;
    font-size: 14px;
    letter-spacing: 1px;
    display: inline-block;
    padding: 12px 32px;
    border-radius: 5px;
    transition: 0.5s;
    line-height: 1;
    margin: 10px;
    color: #fff;
    -webkit-animation-delay: 0.8s;
    animation-delay: 0.8s;
    border: 0;
    background: #428bca;
}
#hero .btn-get-started:hover {
    background: #1c5c93;
}
@media (max-width: 768px) {
    #hero h2 {
        font-size: 28px;
    }
}
@media (max-height: 500px) {
    #hero,
    #hero .carousel-item {
        height: 120vh;
    }
}
@media (min-width: 1024px) {
    #hero p {
        width: 60%;
    }
    #hero .carousel-control-prev,
    #hero .carousel-control-next {
        width: 5%;
    }
}

/*--------------------------------------------------------------
# Sections General
--------------------------------------------------------------*/
section {
    padding: 60px 0;
    overflow: hidden;
}

.section-bg {
    background-color: #f5f9fc;
}

.section-title {
    text-align: center;
    padding-bottom: 30px;
}
.section-title h2 {
    font-size: 32px;
    font-weight: 600;
    margin-bottom: 20px;
    padding-bottom: 0;
    color: #5c768d;
}
.section-title p {
    margin-bottom: 0;
}

/*--------------------------------------------------------------
# Breadcrumbs
--------------------------------------------------------------*/
.breadcrumbs {
    padding: 15px 0;
    background-color: #f5f9fc;
    min-height: 40px;
}
.breadcrumbs h2 {
    font-size: 24px;
    font-weight: 300;
}
.breadcrumbs ol {
    display: flex;
    flex-wrap: wrap;
    list-style: none;
    padding: 0;
    margin: 0;
    font-size: 14px;
}
.breadcrumbs ol li + li {
    padding-left: 10px;
}
.breadcrumbs ol li + li::before {
    display: inline-block;
    padding-right: 10px;
    color: #6c757d;
    content: '/';
}
@media (max-width: 768px) {
    .breadcrumbs .d-flex {
        display: block !important;
    }
    .breadcrumbs ol {
        display: block;
    }
    .breadcrumbs ol li {
        display: inline-block;
    }
}

/*--------------------------------------------------------------
# About Us
--------------------------------------------------------------*/
.about {
    padding-bottom: 30px;
}
.about .container {
    box-shadow: 0 5px 25px 0 rgba(214, 215, 216, 0.6);
}
.about .video-box img {
    padding: 15px 0;
}
.about .section-title p {
    text-align: left;
    font-style: italic;
    color: #666;
}
.about .about-content {
    padding: 40px;
}
.about .icon-box + .icon-box {
    margin-top: 40px;
}
.about .icon-box .icon {
    float: left;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 72px;
    height: 72px;
    background: #f1f7fb;
    border-radius: 6px;
    transition: 0.5s;
}
.about .icon-box .icon i {
    color: #428bca;
    font-size: 32px;
}
.about .icon-box:hover .icon {
    background: #428bca;
}
.about .icon-box:hover .icon i {
    color: #fff;
}
.about .icon-box .title {
    margin-left: 95px;
    font-weight: 700;
    margin-bottom: 10px;
    font-size: 18px;
    text-transform: uppercase;
}
.about .icon-box .title a {
    color: #343a40;
    transition: 0.3s;
}
.about .icon-box .title a:hover {
    color: #428bca;
}
.about .icon-box .description {
    margin-left: 95px;
    line-height: 24px;
    font-size: 14px;
}
.about .video-box {
    position: relative;
}
.about .play-btn {
    width: 94px;
    height: 94px;
    background: radial-gradient(#428bca 50%, rgba(66, 139, 202, 0.4) 52%);
    border-radius: 50%;
    display: block;
    position: absolute;
    left: calc(50% - 47px);
    top: calc(50% - 47px);
    overflow: hidden;
}
.about .play-btn::after {
    content: '';
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translateX(-40%) translateY(-50%);
    width: 0;
    height: 0;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    border-left: 15px solid #fff;
    z-index: 100;
    transition: all 400ms cubic-bezier(0.55, 0.055, 0.675, 0.19);
}
.about .play-btn::before {
    content: '';
    position: absolute;
    width: 120px;
    height: 120px;
    -webkit-animation-delay: 0s;
    animation-delay: 0s;
    -webkit-animation: pulsate-btn 2s;
    animation: pulsate-btn 2s;
    -webkit-animation-direction: forwards;
    animation-direction: forwards;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    -webkit-animation-timing-function: steps;
    animation-timing-function: steps;
    opacity: 1;
    border-radius: 50%;
    border: 5px solid rgba(66, 139, 202, 0.7);
    top: -15%;
    left: -15%;
    background: rgba(198, 16, 0, 0);
}
.about .play-btn:hover::after {
    border-left: 15px solid #428bca;
    transform: scale(20);
}
.about .play-btn:hover::before {
    content: '';
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translateX(-40%) translateY(-50%);
    width: 0;
    height: 0;
    border: none;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    border-left: 15px solid #fff;
    z-index: 200;
    -webkit-animation: none;
    animation: none;
    border-radius: 0;
}

@-webkit-keyframes pulsate-btn {
    0% {
        transform: scale(0.6, 0.6);
        opacity: 1;
    }
    100% {
        transform: scale(1, 1);
        opacity: 0;
    }
}

@keyframes pulsate-btn {
    0% {
        transform: scale(0.6, 0.6);
        opacity: 1;
    }
    100% {
        transform: scale(1, 1);
        opacity: 0;
    }
}
/*--------------------------------------------------------------
# About Lists
--------------------------------------------------------------*/
.about-lists {
    padding: 40px;
}
.about-lists .row {
    overflow: hidden;
}
.about-lists .content-item {
    padding: 40px;
    border-left: 1px solid #d9e8f4;
    border-bottom: 1px solid #d9e8f4;
    margin: -1px;
}
.about-lists .content-item span {
    display: block;
    font-size: 24px;
    font-weight: 400;
    color: #9eccf4;
}
.about-lists .content-item h4 {
    font-size: 28px;
    font-weight: 400;
    padding: 0;
    margin: 20px 0;
}
.about-lists .content-item p {
    color: #aaaaaa;
    font-size: 15px;
    margin: 0;
    padding: 0;
}
@media (max-width: 768px) {
    .about-lists .content-item {
        padding: 40px 0;
    }
}

/*--------------------------------------------------------------
# Counts
--------------------------------------------------------------*/
.counts {
    padding-bottom: 30px;
}
.counts .count-box {
    box-shadow: 0px 0 16px rgba(0, 0, 0, 0.1);
    padding: 30px;
    background: #fff;
    margin-bottom: 30px;
}
.counts .count-box i {
    display: block;
    font-size: 64px;
    margin-bottom: 15px;
}
.counts .count-box span {
    font-size: 42px;
    display: block;
    font-weight: 700;
    color: #1c5c93;
}
.counts .count-box p {
    padding: 0;
    margin: 0;
    font-family: 'Raleway', sans-serif;
    font-size: 14px;
}

/*--------------------------------------------------------------
# Services
--------------------------------------------------------------*/
.services {
    padding-bottom: 30px;
}
.services .icon-box {
    margin-bottom: 20px;
    text-align: center;
}
.services .icon {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    width: 80px;
    height: 80px;
    margin-bottom: 20px;
    background: #fff;
    border-radius: 50%;
    transition: 0.5s;
    color: #428bca;
    box-shadow: 0px 0 25px rgba(0, 0, 0, 0.15);
    overflow: hidden;
}
.services .icon i {
    font-size: 36px;
    line-height: 0;
}
.services .icon-box:hover .icon {
    box-shadow: 0px 0 30px rgba(66, 139, 202, 0.5);
}
.services .title {
    font-weight: 600;
    margin-bottom: 15px;
    font-size: 18px;
    position: relative;
    padding-bottom: 15px;
}
.services .title a {
    color: #444;
    transition: 0.3s;
}
.services .title a:hover {
    color: #428bca;
}
.services .title::after {
    content: '';
    position: absolute;
    display: block;
    width: 50px;
    height: 2px;
    background: #428bca;
    bottom: 0;
    left: calc(50% - 25px);
}
.services .description {
    line-height: 24px;
    font-size: 14px;
}

/*--------------------------------------------------------------
# Our Portfolio
--------------------------------------------------------------*/
.portfolio .portfolio-item {
    margin-bottom: 30px;
}
.portfolio #portfolio-flters {
    padding: 0;
    margin: 0 0 35px 0;
    list-style: none;
    text-align: center;
}
.portfolio #portfolio-flters li {
    cursor: pointer;
    margin: 0 15px 15px 0;
    display: inline-block;
    padding: 10px 20px;
    font-size: 12px;
    line-height: 20px;
    color: #444;
    border-radius: 4px;
    text-transform: uppercase;
    background: #fff;
    margin-bottom: 5px;
    transition: all 0.3s ease-in-out;
}
.portfolio #portfolio-flters li:hover,
.portfolio #portfolio-flters li.filter-active {
    background: #428bca;
    color: #fff;
}
.portfolio #portfolio-flters li:last-child {
    margin-right: 0;
}
.portfolio .portfolio-wrap {
    box-shadow: 0px 2px 12px rgba(0, 0, 0, 0.08);
    transition: 0.3s;
    position: relative;
    overflow: hidden;
}
.portfolio .portfolio-wrap img {
    transition: 0.3s;
}
.portfolio .portfolio-wrap .portfolio-info {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    opacity: 0;
    position: absolute;
    bottom: 0;
    top: 0;
    left: 0;
    right: 0;
    transition: 0.3s;
    text-align: center;
    background: rgba(31, 53, 72, 0.6);
    padding-bottom: 30px;
}
.portfolio .portfolio-wrap .portfolio-info h4 {
    font-size: 20px;
    color: #fff;
    font-weight: 600;
}
.portfolio .portfolio-wrap .portfolio-info p {
    color: #fff;
    font-size: 14px;
    text-transform: uppercase;
}
.portfolio .portfolio-wrap .portfolio-info a {
    color: #428bca;
    margin: 0 4px;
    line-height: 0;
    background-color: #fff;
    border-radius: 50px;
    text-align: center;
    width: 36px;
    height: 36px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    transition: 0.3s;
}
.portfolio .portfolio-wrap .portfolio-info a i {
    font-size: 22px;
    line-height: 0;
}
.portfolio .portfolio-wrap .portfolio-info a:hover {
    background: #428bca;
    color: #fff;
}
.portfolio .portfolio-wrap:hover {
    box-shadow: 0px 4px 14px rgba(0, 0, 0, 0.16);
}
.portfolio .portfolio-wrap:hover .portfolio-info {
    opacity: 1;
    padding-bottom: 0;
}
.portfolio .portfolio-wrap:hover img {
    transform: scale(1.1);
}

/*--------------------------------------------------------------
# Portfolio Details
--------------------------------------------------------------*/
.portfolio-details {
    padding-top: 40px;
}
.portfolio-details .portfolio-details-slider img {
    width: 100%;
}
.portfolio-details .portfolio-details-slider .swiper-pagination {
    margin-top: 20px;
    position: relative;
}
.portfolio-details
    .portfolio-details-slider
    .swiper-pagination
    .swiper-pagination-bullet {
    width: 12px;
    height: 12px;
    background-color: #fff;
    opacity: 1;
    border: 1px solid #428bca;
}
.portfolio-details
    .portfolio-details-slider
    .swiper-pagination
    .swiper-pagination-bullet-active {
    background-color: #428bca;
}
.portfolio-details .portfolio-info {
    padding: 30px;
    box-shadow: 0px 0 30px rgba(31, 53, 72, 0.08);
}
.portfolio-details .portfolio-info h3 {
    font-size: 22px;
    font-weight: 700;
    margin-bottom: 20px;
    padding-bottom: 20px;
    border-bottom: 1px solid #eee;
}
.portfolio-details .portfolio-info ul {
    list-style: none;
    padding: 0;
    font-size: 15px;
}
.portfolio-details .portfolio-info ul li + li {
    margin-top: 10px;
}
.portfolio-details .portfolio-description {
    padding-top: 30px;
}
.portfolio-details .portfolio-description h2 {
    font-size: 26px;
    font-weight: 700;
    margin-bottom: 20px;
}
.portfolio-details .portfolio-description p {
    padding: 0;
}

/*--------------------------------------------------------------
# Our Team
--------------------------------------------------------------*/
.team {
    background: #fff;
    padding: 60px 0 30px 0;
}
.team .member {
    text-align: center;
    margin-bottom: 80px;
    position: relative;
}
.team .member .pic {
    border-radius: 4px;
    overflow: hidden;
}
.team .member img {
    transition: all ease-in-out 0.4s;
}
.team .member:hover img {
    transform: scale(1.1);
}
.team .member .member-info {
    position: absolute;
    bottom: -48px;
    left: 20px;
    right: 20px;
    background: linear-gradient(
        360deg,
        #5c768d 0%,
        rgba(92, 118, 141, 0.9) 35%,
        rgba(140, 167, 191, 0.8) 100%
    );
    padding: 15px 0;
    border-radius: 4px;
}
.team .member h4 {
    font-weight: 700;
    margin-bottom: 10px;
    font-size: 16px;
    color: #fff;
    position: relative;
    padding-bottom: 10px;
}
.team .member h4::after {
    content: '';
    position: absolute;
    display: block;
    width: 50px;
    height: 1px;
    background: #fff;
    bottom: 0;
    left: calc(50% - 25px);
}
.team .member span {
    font-style: italic;
    display: block;
    font-size: 13px;
    color: #fff;
}
.team .member .social {
    margin-top: 15px;
}
.team .member .social a {
    transition: color 0.3s;
    color: #fff;
}
.team .member .social a:hover {
    color: #9eccf4;
}
.team .member .social i {
    font-size: 16px;
    margin: 0 2px;
}
@media (max-width: 992px) {
    .team .member {
        margin-bottom: 100px;
    }
}

/*--------------------------------------------------------------
# Frequently Asked Questions
--------------------------------------------------------------*/
.faq {
    padding-bottom: 30px;
}
.faq .faq-item {
    margin-bottom: 40px;
}
.faq .faq-item h4 {
    font-size: 20px;
    line-height: 28px;
    font-weight: 600;
    margin-bottom: 15px;
    color: #1f3548;
}

/*--------------------------------------------------------------
# Contact Us
--------------------------------------------------------------*/
.contact .info-box {
    color: #444;
    text-align: center;
    box-shadow: 0 0 30px rgba(214, 215, 216, 0.6);
    padding: 20px 0 30px 0;
    margin-bottom: 30px;
    width: 100%;
}
.contact .info-box i {
    font-size: 32px;
    color: #428bca;
    border-radius: 50%;
    padding: 8px;
    border: 2px dotted #9eccf4;
}
.contact .info-box h3 {
    font-size: 20px;
    color: #666;
    font-weight: 700;
    margin: 10px 0;
}
.contact .info-box p {
    padding: 0;
    line-height: 24px;
    font-size: 14px;
    margin-bottom: 0;
}
.contact .php-email-form {
    box-shadow: 0 0 30px rgba(214, 215, 216, 0.6);
    padding: 30px;
}
.contact .php-email-form .error-message {
    display: none;
    color: #fff;
    background: #ed3c0d;
    text-align: left;
    padding: 15px;
    font-weight: 600;
}
.contact .php-email-form .error-message br + br {
    margin-top: 25px;
}
.contact .php-email-form .sent-message {
    display: none;
    color: #fff;
    background: #18d26e;
    text-align: center;
    padding: 15px;
    font-weight: 600;
}
.contact .php-email-form .loading {
    display: none;
    background: #fff;
    text-align: center;
    padding: 15px;
}
.contact .php-email-form .loading:before {
    content: '';
    display: inline-block;
    border-radius: 50%;
    width: 24px;
    height: 24px;
    margin: 0 10px -6px 0;
    border: 3px solid #18d26e;
    border-top-color: #eee;
    -webkit-animation: animate-loading 1s linear infinite;
    animation: animate-loading 1s linear infinite;
}
.contact .php-email-form .form-group {
    margin-bottom: 20px;
}
.contact .php-email-form input,
.contact .php-email-form textarea {
    border-radius: 0;
    box-shadow: none;
    font-size: 14px;
}
.contact .php-email-form input::focus,
.contact .php-email-form textarea::focus {
    background-color: #428bca;
}
.contact .php-email-form input {
    padding: 10px 15px;
}
.contact .php-email-form textarea {
    padding: 12px 15px;
}
.contact .php-email-form button[type='submit'] {
    background: #428bca;
    border: 0;
    padding: 10px 30px;
    color: #fff;
    transition: 0.4s;
}
.contact .php-email-form button[type='submit']:hover {
    background: #629fd3;
}
@-webkit-keyframes animate-loading {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}
@keyframes animate-loading {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

#footer {
    background: #587187;
    padding: 0 0 30px 0;
    color: #fff;
    font-size: 14px;
}
#footer .footer-top {
    background: #5c768d;
    border-top: 1px solid #768fa6;
    border-bottom: 1px solid #67839c;
    padding: 60px 0 30px 0;
}
#footer .footer-top .footer-info {
    margin-bottom: 30px;
}
#footer .footer-top .footer-info h3 {
    font-size: 24px;
    margin: 0 0 20px 0;
    padding: 2px 0 2px 0;
    line-height: 1;
    font-weight: 700;
}
#footer .footer-top .footer-info p {
    font-size: 14px;
    line-height: 24px;
    margin-bottom: 0;
    font-family: 'Raleway', sans-serif;
    color: #fff;
}
#footer .footer-top .social-links a {
    font-size: 18px;
    display: inline-block;
    background: #768fa6;
    color: #fff;
    line-height: 1;
    padding: 8px 0;
    margin-right: 4px;
    border-radius: 50%;
    text-align: center;
    width: 36px;
    height: 36px;
    transition: 0.3s;
}
#footer .footer-top .social-links a:hover {
    background: #428bca;
    color: #fff;
    text-decoration: none;
}
#footer .footer-top h4 {
    font-size: 16px;
    font-weight: 600;
    color: #fff;
    position: relative;
    padding-bottom: 12px;
}
#footer .footer-top .footer-links {
    margin-bottom: 30px;
}
#footer .footer-top .footer-links ul {
    list-style: none;
    padding: 0;
    margin: 0;
}
#footer .footer-top .footer-links ul i {
    padding-right: 2px;
    color: #9eccf4;
    font-size: 18px;
    line-height: 1;
}
#footer .footer-top .footer-links ul li {
    padding: 10px 0;
    display: flex;
    align-items: center;
}
#footer .footer-top .footer-links ul li:first-child {
    padding-top: 0;
}
#footer .footer-top .footer-links ul a {
    color: #fff;
    transition: 0.3s;
    display: inline-block;
    line-height: 1;
}
#footer .footer-top .footer-links ul a:hover {
    color: #9eccf4;
}
#footer .footer-top .footer-newsletter form {
    margin-top: 30px;
    background: #fff;
    padding: 6px 10px;
    position: relative;
    border-radius: 4;
}
#footer .footer-top .footer-newsletter form input[type='email'] {
    border: 0;
    padding: 4px;
    width: calc(100% - 110px);
}
#footer .footer-top .footer-newsletter form input[type='submit'] {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    border: 0;
    background: none;
    font-size: 16px;
    padding: 0 20px;
    background: #428bca;
    color: #fff;
    transition: 0.3s;
    border-radius: 4;
}
#footer .footer-top .footer-newsletter form input[type='submit']:hover {
    background: #5295ce;
}
#footer .copyright {
    text-align: center;
    padding-top: 30px;
}
#footer .credits {
    padding-top: 10px;
    text-align: center;
    font-size: 13px;
    color: #fff;
}
#footer .credits a {
    color: #9eccf4;
}

/* overwrite container max width */
@media (min-width: 1200px) {
    .container {
        max-width: 1440px !important;
    }
}
