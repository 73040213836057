.article .article-title {
    font-weight: 700;
    font-size: 24px;
    line-height: 36px;
    color: #2b2e38;
}

.article .article-sub-header {
    display: flex;
    justify-content: space-between;

    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
    color: #2b2e38;
}

.article .section-info {
    display: flex;
    gap: 6px;
    align-items: center;
}

.article .section-share {
    display: flex;
    gap: 6px;
    align-items: center;
}

.article .section-share img {
    cursor: pointer;
}

.article .content-text {
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    color: #7a8599;
}


.dark .article .article-title,
.dark .article .section-info,
.dark .article-sub-header .section-share {
    color: white;
}