.data-correction-eform .form-section {
    font-size: 20px;
    font-weight: 600;
    width: 100%;
}

.data-correction-eform .form-title {
    font-size: 16px;
    font-weight: 600;
    width: 100%;
}
