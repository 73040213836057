/* .funding-deposit-container .input-container > .label {
    width: 120px;
} */

.funding-deposit-container .custom-input-div {
    /* padding-left: 12px; */
    width: 100%;
    max-width: 100%;
}

.funding-deposit-container .custom-input-div select,
.funding-deposit-container .custom-input-div input,
.funding-deposit-container .custom-input-div textarea {
    width: 100%;
    max-width: 100%;
}

.funding-deposit-container .row-submit {
    display: flex;
    justify-content: flex-end;
    padding-right: 30px;
}

@media (max-width: 1000px) {
    .funding-deposit-container .custom-input-div {
        padding-left: 0px;
    }

    .funding-deposit-container .row-submit {
        display: flex;
        justify-content: flex-start;
        padding-right: 0px;
    }
}
