.react-modal {
    position: relative;
    background-color: rgb(255, 255, 255, 0.97);
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 888;
    /* overscroll-behavior: contain; */
    opacity: 0;
    transition: 0.5s;
    overflow: auto;
}

.react-modal.hide {
    /* visibility: hidden; */
    animation: modalSlideDown 0.5s cubic-bezier(0.165, 0.84, 0.44, 1) forwards;
    /* display: none; */
}

.react-modal.show {
    display: block;
    opacity: 1;
    animation: modalSlideUp 0.5s cubic-bezier(0.165, 0.84, 0.44, 1) forwards;
    /* -webkit-transition: opacity 0.3s ease-out, bottom 0.3s ease-out;
    -moz-transition: opacity 0.3s ease-out, bottom 0.3s ease-out;
    -o-transition: opacity 0.3s ease-out, bottom 0.3s ease-out;
    transition: opacity 0.3s ease-out, bottom 0.3s ease-out; */
}

.react-modal > .content > .header {
    display: flex;
    justify-content: space-between;
    padding: 1rem 2rem 1rem 2rem;
    background-color: white;
}

.react-modal > .content > .header > .left-sec > i {
    cursor: pointer;
    font-size: 1.6rem;
    color: black;
}

.react-modal > .content > .header > .left-sec > i:hover {
    color: #7a8599;
    transition: 0.3s;
    transform: translateY(-2px);
}

.react-modal > .content > .body {
    transition: 1s;
    opacity: 0;
    background-color: white;
}

.react-modal > .content > .body.show {
    opacity: 1;
    animation: modalSlideUp 0.8s cubic-bezier(0.165, 0.84, 0.44, 1) ease-in-out;
}

.react-modal > .content > .body.hide {
    opacity: 0;
    animation: modalSlideDown 0.8s cubic-bezier(0.165, 0.84, 0.44, 1) forwards;
}

.article-title {
    font-weight: 700;
    color: #2b2e38;
}

.dark .article-title {
    font-weight: 700;
    color: #FFFFFF;
}

@keyframes modalSlideUp {
    0% {
        transform: translateY(100%);
    }
    100% {
        transform: translateY(0%);
    }
}

@keyframes modalSlideDown {
    0% {
        transform: translateY(0);
        height: initial;
    }
    100% {
        transform: translateY(100%);
        height: 0;
    }
}
