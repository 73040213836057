.login-form-container {
    min-height: 92vh;
}

.login-page-container {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
}

.login-page-bg {
    background-size: 100% 100%;
    background-repeat: no-repeat;
    width: 100%;
    height: 100%;
    /* Add the blur effect */
    filter: blur(30px);
    backdrop-filter: brightness(10%);
    position: absolute;
    z-index: -1;
}

.login-form-block {
    background-size: 100% 100%;
    background-repeat: no-repeat;
    height: 580px;
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
    border-radius: 12px;
}

.login-form {
    background-color: white;
    height: 100%;
    border-top-right-radius: 12px;
    border-bottom-right-radius: 12px;
}

.login-form .title {
    font-weight: 700;
    font-size: 24px;
    line-height: 36px;
    color: var(--dark-4);
}

@media only screen and (max-width: 600px) {
    .login-form {
        border-radius: 12px;
    }
}
