:root {
    --primary-text: #2b2e38;
    --primary-text-dark: white;
    --secondary-text-dark: #c1c6cf;
    --light-gray: #c1c6cf;
    --light-gray-2: #fafbff;
    --light-gray-3: #f1f3f9;
    --light-gray-4: #7a8599;
    --dark-2: #212533;
    --dark-4: #2b2e38;
    --dark-5: #1C1C24;
    --light-green-1: #8effd1;
    --light-green-3: #439782;
    --light-red-2: #EB5533;
    --blue-1: #00218f;
    --black-1: #1c1d24;

    --font-size-md: 1rem;  /*around 16px */
}

/* overwrite antd style */
h1,
h2,
h3,
h4,
h5,
h6 {
    margin-top: 0;
    margin-bottom: 0;
    color: inherit;
    font-weight: unset;
}

h4 {
    font-weight: 700;
}

.custom-nav .nav-link {
    color: var(--light-gray-4);
}

.custom-nav .nav-link.active {
    border: none !important;
    background-color: inherit !important;
    border-color: inherit !important;
    color: #439782;
    border-bottom: 3px solid #439782 !important;
}

.custom-nav .nav-link {
    border: 0px solid transparent !important;
}

.custom-form {
    width: 227.43px;
    height: 44px;
    /* Neutral/300 */
    border: 1px solid #cbd5e1;
    border-radius: 8px;
}

iframe {
    width: 100%;
    height: calc(100vh - 105px);
}

.tds-iframe-container > .row > div > .iframe-block {
    margin-left: -3%;
}

.iframe-container {
    display: flex;
    width: 100%;
    height: 100%;
    flex-direction: column;
}

.btn-primary-custom {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    padding: 16px;
    gap: 8px;

    width: 227.43px;
    height: 56px;

    /* Blue */
    color: white;
    background: #3268f6;
    border: none;
    border-radius: 8px;
}

.btn-primary-custom.btn-md {
    width: 227.43px;
    max-width: 227.43px;
    height: 44px;
    padding: 10px 16px;
}

.btn-primary-custom i {
    font-size: 16px;
}

.btn-primary-custom.btn-light {
    background: #f1f5f9;
    color: #2b2e38;
}

.custom-hr {
    border-bottom: 1px solid var(--light-gray);
}

.custom-tag{
    font-size: 14px;
    padding: 0px 12px;
    color: #fff;
    border: 1px solid transparent;
    border-radius: 56px;
    text-align: center;
    background: #169baf;
    white-space: normal;
    max-width: fit-content;
    min-width: 70px;
    width: fit-content;
}

select.underline {
    display: block;
    width: 100%;
    font-size: 1em;
    padding: 0.3em 3.5em 0.3em 0.5em;
    border: 0;
    border-bottom: 1px solid black;
    border-radius: 0;
    background: transparent;
    /** for the dropdown indicator */
    appearance: none;
    background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='none' stroke='currentColor' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3e%3cpolyline points='6 9 12 15 18 9'%3e%3c/polyline%3e%3c/svg%3e");
    background-repeat: no-repeat;
    background-position: right 1rem center;
    background-size: 1em;
}

div.custom-text {
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    color: #2b2e38;
}

textarea.custom {
    width: auto;
    height: auto;

    color: var(--dark-4);
    font-size: 14px;
    font-weight: 400;
    line-height: 21px;
    padding: 10px 10px 10px 10px;
    border: 0;
    background: transparent;
    border: 1px solid var(--light-gray);
    border-radius: 8px;
}
input.custom,
input.custom-keyword {
    /* width: 227.43px; */
    width: 100%;
    height: 44px;

    color: var(--dark-4);
    font-size: 14px;
    font-weight: 400;
    line-height: 21px;
    padding: 10px 10px 10px 10px;
    border: 0;
    background: transparent;
    border: 1px solid var(--light-gray);
    border-radius: 8px;
}

.ant-select-selector {
    /* width: 227.43px; */
    width: 100%;
    min-height: 44px;
    height: 100%;

    color: var(--dark-4);
    font-size: 14px;
    font-weight: 400;
    line-height: 21px;
    padding: 10px 10px 10px 10px;
    border: 0;
    background: transparent;
    border: 1px solid var(--light-gray);
    border-radius: 8px;
}

input.custom:focus,
.ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input)
    .ant-select-selector {
    border: 2px solid black;
    box-shadow: 0 0 0 0 rgb(45, 45, 46);
    transition: 0.1s;
}

.ant-select:not(.ant-select-customize-input) .ant-select-selector {
    border-radius: 8px;
}

.ant-select:not(.ant-select-disabled):focus .ant-select-selector {
    border: 2px solid black;
    box-shadow: 0 0 0 0 rgb(45, 45, 46);
}
.ant-select:not(.ant-select-disabled):hover .ant-select-selector {
    border-color: inherit;
}

input.custom.border-bottom {
    border: 0px;
    border-bottom: 1px solid var(--light-gray) !important;
    border-radius: 0px;
}

select.custom {
    display: block;
    /* width: 227.43px; */
    width: 100%;
    height: 44px;

    color: var(--dark-4);
    font-size: 14px;
    font-weight: 400;
    line-height: 21px;
    padding: 10px 32px 10px 10px;
    border: 0;
    background: transparent;
    border: 1px solid var(--light-gray);
    border-radius: 8px;
    cursor: pointer;

    /** for the dropdown indicator */
    appearance: none;
    background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='none' stroke='currentColor' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3e%3cpolyline points='6 9 12 15 18 9'%3e%3c/polyline%3e%3c/svg%3e");
    background-repeat: no-repeat;
    background-position: right 1rem center;
    background-size: 20px;
}

.timepicker.custom {
    display: block;
    /* width: 227.43px; */
    width: 100%;
    height: 44px;

    color: var(--dark-4);
    font-size: 14px;
    font-weight: 400;
    line-height: 21px;
    padding: 10px 32px 10px 10px;
    border: 0;
    background: transparent;
    border: 1px solid var(--light-gray);
    border-radius: 8px;
    box-shadow: none !important;
}

.daterangepicker.custom {
    /* width: 227.43px; */
    width: 100%;
    height: 44px;
    color: var(--dark-4);
    font-size: 14px;
    font-weight: 400;
    line-height: 21px;
    padding: 10px 32px 10px 10px;
    border: 0;
    background: transparent;
    border: 1px solid var(--light-gray);
    border-radius: 8px;
    box-shadow: none !important;
}

.timepicker.custom input,
.daterangepicker.custom input {
    cursor: pointer;
}

.timepicker.custom input::placeholder,
.daterangepicker.custom input::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: inherit;
    opacity: 1; /* Firefox */
}

.timepicker.custom input::-ms-input-placeholder,
.daterangepicker.custom input::-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: inherit;
}

.timepicker.custom input::-ms-input-placeholder,
.daterangepicker.custom input::-ms-input-placeholder {
    /* Microsoft Edge */
    color: inherit;
}

/* antd datepicker popout */
.ant-picker-panel-container {
    border: 1px solid #ddd;
    border-radius: 8px;
    box-shadow: 0px 10px 22px rgb(45 77 108 / 15%);
}

/* antd range picker always display clearall icon */
.ant-picker-clear {
    opacity: 1;
}

/* Antd custom modal*/
.ant-modal,
.ant-modal-header,
.ant-modal-content {
    border-radius: 8px;
    /* Only border-radius here */
}

/* display antd clear select icon */
.ant-select-clear {
    opacity: 1;
   
}


.custom-form-group input {
    width: 296px;
    height: 56px;
    border: 1px solid #c1c6cf;
    border-radius: 8px;
}

.custom-form-group input::placeholder {
    color: var(--dark-4);
}

.btn.custom-btn-primary {
    width: 296px;
    height: 56px;
    color: #ffffff;
    background: #3268f6;
    border-radius: 8px;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
}

.btn.custom-btn-secondary {
    width: 296px;
    height: 56px;
    color: var(--dark-4);
    background: #f1f3f9;
    border-radius: 8px;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
}

.btn:hover {
    opacity: 0.9;
}

.custom-href {
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    color: #3268f6 !important;
    cursor: pointer;
}

.custom-href:hover {
    text-decoration: underline !important;
}

.input-column-gap {
    /* column-gap: 20px; */
}

.form-check label {
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    color: #0f172a;
}

.form-input-container {
    display: flex;
    flex-direction: column;
    align-items: baseline;
    gap: 5px;
    /* align-items: center; */
    font-weight: 400;
    font-size: 16px;
    line-height: 21px;
    color: var(--dark-4);
    padding-right: 20px;
    /* padding: 10px 32px 10px 0px; */
}

.form-input-container > .label {
    padding-top: 10px;
    padding-right: 25px;
    width: 100%;
    /* min-width: 120px; */
    max-width: 590px;
    font-weight: 600;
}

.input-container {
    display: flex;
    flex-direction: column;
    align-items: baseline;
    gap: 3px;

    font-weight: 400;
    font-size: 16px;
    line-height: 21px;
    color: var(--dark-4);
    /* padding: 10px 32px 10px 0px; */
    /* padding: 10px 0px 10px 0px; */
    /* padding: 0px 0px 10px 0px; */
    margin-bottom: 16px;
    /* padding-right: 20px; */
}

.input-container .label {
    /* padding-left: 15px; */
    /* padding-right: 15px; */
    /* min-width: 90px; */
    width: 100%;
    font-weight: 600;
}

.custom-label {
    width: 100%;
    height: 44px;
    font-weight: 400;
    font-size: 16px;
    line-height: 21px;
    color: var(--dark-4);
    display: flex;
    align-items: center;
}

.input-group-container {
    display: flex;
    flex-wrap: wrap;
}

.main-title {
    font-weight: 900;
    font-size: 24px;
    line-height: 36px;
    color: var(--dark-4);
    padding-bottom: 16px;
}

/* funding pages start */
.total-summary-container {
    display: flex;
    flex-direction: column;
    gap: 16px;
}
.total-summary-container .item {
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    letter-spacing: 0.01em;
    color: var(--dark-4);

    display: flex;
}

.total-summary-container .title {
    width: 120px;
}

.total-summary-container .value {
    font-weight: 600;
}

/* funding pages end */

/* footer style */
footer {
    min-height: 50px;
    margin-top: auto;
    text-align: center;
    color: white;
    display: flex;
    justify-content: center;
}
footer .footer-container {
    width: 98%;
    border-top: 1px solid rgb(255, 255, 255, 0.25);
    padding-top: 16px;
    font-size: 14px;
}

footer .footer-container.protected {
    width: 100%;
    border-top: 1px solid var(--light-gray);
    color: var(--light-gray-4);
    text-align: left;

    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
}

.showElement {
    display: block;
}

.hideElement {
    display: none;
}

/* /////////////// */
/* dark theme */
/* /////////////// */

.dark select.custom {
    color: white;
    background-color: var(--dark-5);
    background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='none' color='white' stroke='currentColor' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3e%3cpolyline points='6 9 12 15 18 9'%3e%3c/polyline%3e%3c/svg%3e");
    border: 1px solid #7a8599;
    /* background-color: #ffffff;
    border: 1px solid var(--light-gray); */
}

.dark input.custom-tel {
    color: white;
    background-color: var(--dark-2);
}
.dark .custom-label {
    color: white;
}

.dark h1,
.dark h2,
.dark h3,
.dark h4,
.dark h5,
.dark h6 {
    color: white;
    font-weight: inherit;
    margin: inherit;
}

.dark .page-content .main-title,
.dark .page-content .input-container .label {
    color: white;
}

.dark .ant-picker-range {
    background-color: var(--dark-5);
}

.dark .ant-picker-range input {
    color: white;
}

.dark .ant-picker-range input::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: white;
    opacity: 1; /* Firefox */
}

.dark .ant-picker-range input::-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: white;
}

.dark .ant-picker-range input::-ms-input-placeholder {
    /* Microsoft Edge */
    color: white;
}

.dark .ant-picker-suffix {
    color: white;
}

.dark .ant-picker-clear svg {
    background: var(--dark-2);
    color: rgb(234, 41, 41);
}

.dark .ant-picker-panel-container,
.dark .ant-picker-header-view,
.dark .ant-picker-header-super-prev-btn,
.dark .ant-picker-header-prev-btn,
.dark .ant-picker-header-next-btn,
.dark .ant-picker-header-super-next-btn
 {
    background: var(--dark-4);
    color: white;
}



.dark .ant-picker-content th,
.dark .ant-picker-cell-in-view {
    color: rgb(196, 196, 196);
}

.dark .ant-picker-cell-disabled{
    color: rgba(0,0,0,.75);
}



.dark input.custom {
    color: white;
    /* background-color: var(--dark-2); */
}

.dark .ant-select-selection-item {
    background: var(--light-gray-4);
    color: white;
    border: none;
}

/* multipl select */
.dark .ant-select-dropdown,
.dark .ant-select-tree{
    background: var(--dark-4);
    color: white;

    border-radius: 8px;
 
}

.dark .ant-select-tree .ant-select-tree-treenode-disabled .ant-select-tree-node-content-wrapper {
    color: rgba(255, 255, 255, 0.25);
    cursor: not-allowed;
}

.dark .react-modal > .content > .header > .left-sec > i,
.dark .ant-select-clear,
.dark .ant-empty-description {
    background: inherit;
    color: white;
}




.dark .ant-select-tree .ant-select-tree-node-content-wrapper:hover {
    background-color: var(--light-gray);
}


.dark .react-modal > .content > .header,
.dark .react-modal > .content > .body {
    background: var(--dark-5);
    color:white
}


.dark input.custom.custom-number,
.dark input.custom.custom-text {
    background-color: var(--dark-5);
}

.dark input.custom-keyword {
    color: white;
    background-color: var(--dark-5);
}

.dark .form-input-container > .label,
.dark div.custom-text {
    color: white;
}

.dark .timepicker.custom {
    background-color: var(--dark-5);
}

.dark .timepicker.custom input,
.dark .funding-total-deposit-amount,
.dark .dark-text-white {
    color: white;
}

.dark .ant-select:not(.ant-select-customize-input) .ant-select-selector {
    background-color: var(--dark-5);
}

.dark .total-summary-container .item {
    color: white;
}

.dark .ant-modal-content {
    background-color: var(--dark-4);
    color:white
}

.dark .ant-modal-content .anticon svg {
    color: white;
}

/* /////////////// */
/* dark theme end */
/* /////////////// */

ol.ol-custom-text {
    counter-reset: item;
    margin-left: 0;
    padding-left: 0;
}
ol.ol-custom-text > li {
    display: block;
    margin-bottom: 0.5em;
    margin-left: 3em;
}
ol.ol-custom-text > li::before {
    display: inline-block;
    content: '(' counter(item, lower-alpha) ') ';
    counter-increment: item;
    width: 3em;
    margin-left: -3em;
}

ol.ol-custom-text-upper {
    counter-reset: item;
    margin-left: 0;
    padding-left: 0;
}
ol.ol-custom-text-upper > li {
    display: block;
    margin-bottom: 0.5em;
    margin-left: 3em;
}
ol.ol-custom-text-upper > li::before {
    display: inline-block;
    content: '(' counter(item, upper-alpha) ') ';
    counter-increment: item;
    width: 3em;
    margin-left: -3em;
}

ol.ol-custom-number {
    counter-reset: item;
    margin-left: 0;
    padding-left: 0;
}
ol.ol-custom-number > li {
    display: block;
    margin-bottom: 0.5em;
    margin-left: 3em;
}
ol.ol-custom-number > li::before {
    display: inline-block;
    content: '(' counter(item) ') ';
    counter-increment: item;
    width: 3em;
    margin-left: -3em;
}

ol.ol-custom-roman {
    counter-reset: item;
    margin-left: 0;
    padding-left: 0;
}
ol.ol-custom-roman > li {
    display: block;
    margin-bottom: 0.5em;
    margin-left: 3em;
}
ol.ol-custom-roman > li::before {
    display: inline-block;
    content: '(' counter(item, lower-roman) ') ';
    counter-increment: item;
    width: 3em;
    margin-left: -3em;
}

.sidebar .nav-item .nav-link .badge-counter, .topbar .nav-item .nav-link .badge-counter{
    position: relative;
    transform: scale(0.7);
    /* transform-origin: top left; */
    /* left: 4rem; */
    margin-top: -0.25rem;
    right: 15px;
}

.hover-unset{
    color: unset!important;
}

.hover-custom:hover{
    color: #b7b9cc;
}




/* custom scrollbar */
*::-webkit-scrollbar {
    width: 10px;
}

/* Track */
*::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px grey;
    border-radius: 10px;
    background-color: #d6dee1;
}

/* Handle */
*::-webkit-scrollbar-thumb {
    background-color: white;

    border-radius: 20px;
    border: 3px solid transparent;
    background-clip: content-box;
}

/* Handle on hover */
*::-webkit-scrollbar-thumb:hover {
    /* background: #b30000;  */
}
/* custom scrollbar end*/

/* animation effect */
@-webkit-keyframes fadeInDown {
    0% {
        opacity: 0;
        -webkit-transform: translateY(-20px);
    }
    100% {
        opacity: 1;
        -webkit-transform: translateY(0);
    }
}

@keyframes fadeInDown {
    0% {
        opacity: 0;
        transform: translateY(-20px);
    }
    100% {
        opacity: 1;
        transform: translateY(0);
    }
}

@media only screen and (max-width: 1400px) {
    .tds-iframe-container > .row > div > .iframe-block {
        margin-left: -2%;
    }

    .custom-form-group input,
    .btn.custom-btn-primary,
    .btn.custom-btn-secondary {
        width: 100%;
    }
}

@media only screen and (max-width: 1000px) {
    .tds-iframe-container > .row > div > .iframe-block {
        margin-left: 0;
    }
    /* 
    .input-container,
    .form-input-container {
        flex-direction: column;
        align-items: baseline;
        gap: 3px;
    }

    .input-container .label,
    .form-input-container .label {
        width: 100%;
    } */
}

@media only screen and (max-width: 600px) {
    .public-navbar-container {
        display: none;
    }
}

/* custom bootstrap grid */
/* BS4 Grid xxxl breakpoint */

/* adding col-xxxl */
.col-xxxl,
.col-xxxl-1,
.col-xxxl-2,
.col-xxxl-3,
.col-xxxl-4,
.col-xxxl-5,
.col-xxxl-6,
.col-xxxl-7,
.col-xxxl-8,
.col-xxxl-9,
.col-xxxl-10,
.col-xxxl-11,
.col-xxxl-12,
.col-xxxl-auto {
    position: relative;
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
}

@media (min-width: 1770px) {
    /* expanding BS4 max-width */
    /* .container {
        max-width: 1540px;
    } */

    /* grid columns xxxl */
    .col-xxxl {
        -ms-flex-preferred-size: 0;
        flex-basis: 0;
        -webkit-box-flex: 1;
        -ms-flex-positive: 1;
        flex-grow: 1;
        max-width: 100%;
    }
    .col-xxxl-auto {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: auto;
        max-width: none;
    }
    .col-xxxl-1 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 8.333333%;
        flex: 0 0 8.333333%;
        max-width: 8.333333%;
    }
    .col-xxxl-2 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 16.666667%;
        flex: 0 0 16.666667%;
        max-width: 16.666667%;
    }
    .col-xxxl-3 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 25%;
        flex: 0 0 25%;
        max-width: 25%;
    }
    .col-xxxl-4 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 33.333333%;
        flex: 0 0 33.333333%;
        max-width: 33.333333%;
    }
    .col-xxxl-5 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 41.666667%;
        flex: 0 0 41.666667%;
        max-width: 41.666667%;
    }
    .col-xxxl-6 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 50%;
        flex: 0 0 50%;
        max-width: 50%;
    }
    .col-xxxl-7 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 58.333333%;
        flex: 0 0 58.333333%;
        max-width: 58.333333%;
    }
    .col-xxxl-8 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 66.666667%;
        flex: 0 0 66.666667%;
        max-width: 66.666667%;
    }
    .col-xxxl-9 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 75%;
        flex: 0 0 75%;
        max-width: 75%;
    }
    .col-xxxl-10 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 83.3333333%;
        flex: 0 0 83.33333333%;
        max-width: 83.33333333%;
    }
    .col-xxxl-11 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 91.66666667%;
        flex: 0 0 91.66666667%;
        max-width: 91.66666667%;
    }
    .col-xxxl-12 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 100%;
        flex: 0 0 100%;
        max-width: 100%;
    }
}

@media (max-width: 576px) {
    .ant-picker-panels {
        flex-direction: column;
    }
}

#success {
    background: #03a679;
    color: #f0f0f0;
}

#failure {
    background: #ff3939;
    color: #f0f0f0;
}

.loading-notify-container {
    position: fixed;
    width: 100%;
    top: 0px;
    display: flex;
    justify-content: center;
    padding-top: 15px;
    pointer-events: none;

    z-index: 9199;
}
.loading-notify-container .notify {
    position: relative;
    width: fit-content;
    height: 100%;

    display: flex;
    justify-content: center;
    align-items: center;

    text-align: center;
    overflow: hidden;

    /* animation: MoveUpDown 5s linear; */
    transition: 0.3s all;
    opacity: 0;
    transform: translateY(-50px);
    font-size: 14px !important;
    font-weight: 200 !important;

    padding: 8px 16px;
    background: #fff;
    border-radius: 2px;
    box-shadow: 0 3px 6px -4px rgb(0 0 0 / 12%), 0 6px 16px 0 rgb(0 0 0 / 8%),
        0 9px 28px 8px rgb(0 0 0 / 5%);
    pointer-events: all;

    color: black;

    font-variant: tabular-nums;
    line-height: 1.5715;
    list-style: none;
}

#notifyType:before {
    display: block;
    margin-top: 15px;
}

.loading-notify-container #notifyType {
    display: flex;
    gap: 10px;
}

.loading-notify-container .notify.active {
    /* height: 50px; */
    opacity: 1;
    transform: translateY(0);
}

.loading-notify-container .notify.close {
    /* height: 50px; */
    /* font-size: 14px;*/
    opacity: 0;
    transform: translateY(-50px);
}

/* overwrite boostrap mobile view */
@media (min-width: 576px) {
    .container-sm, .container {
      max-width: 100%;
    }
}
