.react-table-group {
    border-spacing: 10px;
    border-collapse: collapse;
    /* border: 1px solid black; */
    width: 100%;
}

.react-table-group > thead {
    background-color: #3268f6;
    color: white;
}

.react-table-group > thead > tr > th {
    padding: 13px 15px;
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
}

.react-table-group > thead > tr > th:first-child {
    border-radius: 12px 0px 0px 0px;
}

.react-table-group > thead > tr > th:last-child {
    border-radius: 0px 12px 0px 0px;
}

.react-table-group > tbody > tr {
    border-bottom: 1px solid var(--light-gray);
}

.react-table-group > tbody > tr > td {
    margin: 0;
    padding: 13px 20px;
    font-weight: 400;
    /* font-size: 14px; */
    font-size: var(--font-size-md);
    line-height: 21px;
    color: #2b2e38;
}

.react-table-group > tbody > tr:hover {
    background-color: #d8f1ff;
}

.react-table-group > tbody > tr button {
    font-size: 14px;
    width: 100px;
}

.react-table-group.mobile-portfolio > thead > tr > th {
    padding: 5px 10px;
    font-size: 0.65rem;
}

.react-table-group.mobile-portfolio > tbody > tr > td {
    padding: 5px 10px;
    font-size: 0.65rem;
}

.react-table-group .no-data {
    /* display: flex;
    justify-content: center;
    align-items: center; */
    text-align: center;
    width: 100%;
    height: 200px;
    background: rgb(246, 245, 245);
    border: 0px solid #fff;
    color: #bfc0c5;
    font-size: 17px;
}

/* /////////////// */
/* dark theme */
/* /////////////// */

.dark .react-table-group > tbody > tr  > td.isAggregated {
    background:var(--dark-4)!important;
}

.dark .react-table-group > tbody > tr > td {
    background: var(--dark-5) !important;
    color: var(--light-gray);
}

.dark .react-table-group > tbody > tr {
    border-bottom: 1px solid var(--light-gray-4);
}

.dark .react-table-group .pagination-container {
    background-color: var(--light-gray-4);
    color: var(--light-gray);
}



/* searchbox style */
.search-box {
    position: relative;
    width: 227.43px;
    height: 44px;
    font-size: 14px;
    /* Basic/White */
    /* 
    background: #ffffff; */
    background: inherit;
    /* Light Grey 02 */
}

.search-box > input {
    position: relative;
    width: 100%;
    height: 100%;
    padding-left: 15%;
    border: 1px solid var(--light-gray);
    border-radius: 8px;
    cursor: pointer;
}

.search-box > i {
    position: absolute;
    width: 16px;
    height: 16px;
    left: 10px;
    top: 35%;
    color: var(--dark-4);
}

/* .search-box:focus > i {
    background-color: red;
} */

.search-box .dropdown-list {
    width: 100%;
    box-sizing: border-box;
    transition: all 0.15s cubic-bezier(0.25, 0, 0.25, 1.75), opacity 0.1s linear;

    background: #ffffff;
    /* Elevation / Level 3 */
    box-shadow: 0px 10px 22px rgba(45, 77, 108, 0.15);
    border-radius: 8px;

    margin-top: 4px;
    padding: 3px 0;
    opacity: 0;
    display: none;
    overflow: hidden;
    position: absolute;
    top: 100%;
    left: 0;
    z-index: 999;
    max-height: 250px;
    overflow: auto;
    border: 1px solid #ddd;
}

.search-box > .dropdown-list.show {
    opacity: 1;
    display: block;

    -webkit-animation: fadeInDown 0.3s;
    animation: fadeInDown 0.3s;
    animation-fill-mode: forwards;
}

.search-box > .dropdown-list.pointerEventNone {
    pointer-events: none;
}

.search-box > .dropdown-list > ul {
    width: 100%;
    padding: 0;
    position: relative;
    padding: 2px;
    cursor: pointer;
}

.search-box > .dropdown-list > ul > li {
    padding: 7.5px 16px;
    border-radius: 8px;
}

.search-box > .dropdown-list > ul > .option:hover,
.search-box > .dropdown-list > ul > .option:focus {
    background-color: #f1f5f9;
}

.search-box .option.selected {
    font-weight: 600;
    color: var(--dark-2);
}

.search-box .option.selected:focus {
    background: #f6f6f6;
}

.search-box input[type='search']::-webkit-search-decoration,
.search-box input[type='search']::-webkit-search-cancel-button,
.search-box input[type='search']::-webkit-search-results-button,
.search-box input[type='search']::-webkit-search-results-decoration {
    -webkit-appearance: none;
}

.dark .search-box > input {
    background-color: var(--dark-5);
}

.dark .search-box > i {
    color: white;
}
