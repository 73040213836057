.header-container {
    /* background-color: #212533; */
    background-color: #eeecf1;
    color: #1c5c93;
    width: 100%;
    height: auto;
}

.dark .header-container {
    background-color: #212533;
    color: #eeecf1;
    width: 100%;
    height: auto;
}

.header-container .topbar {
    height: 78px;
}
.navbar-expand {
    justify-content: flex-end;
}

.navbar-title {
    padding-top: 1rem;
    font-size: 40px;
    font-weight: 400;
}

.topbar.navbar-light .navbar-nav .nav-item .nav-link {
    color: #1c5c93;
}

.header-container .dropdown-item {
    cursor: pointer;
}

.mobile-side-nav {
    position: fixed;
    display: none;
    z-index: 1;
    height: 100%;

    /* background-color: rgba(255, 255, 255, 0.8); */
    /* overflow: auto; */
}

.mobile-side-nav.show {
    display: block;
}

.mobile-side-nav .sidebar {
    width: 6.5rem;
    min-height: 100vh;
    position: relative;
    height: auto;
}

.mobile-side-menu-btn {
    float: left;
    display: none;
    align-items: center;
    justify-content: center;
    /* justify-content: center; */
    /* flex-direction: row; */
    /* align-content: center; */
    height: 100%;
}

.mobile-side-nav img {
    /* white */
    filter: invert(100%) sepia(100%) saturate(0%) hue-rotate(109deg)
        brightness(102%) contrast(102%);
}

@media only screen and (max-width: 1200px) {
    .mobile-side-menu-btn {
        display: flex;
    }
}
@media only screen and (max-width: 600px) {
    .navbar-title {
        padding-top: 0;
        font-size: 20px;
        font-weight: 400;
    }
}
