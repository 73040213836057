.card-list-container {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    padding-top: 16px;
}

.card-list-container .item {
    width: 235px;
    height: 358.49px;
}

.card-list-container .item:hover {
    cursor: pointer;
    opacity: 0.6;
}

.card-list-container .item > img {
    width: 100%;
}

.card-list-container .item > .img-div {
    width: 100%;
    height: 140px;
}

.card-list-container .item .category {
    margin-top: 8px;
}

.card-list-container .item .sub-title {
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    color: var(--dark-4);
    padding-top: 5px;
}

.card-list-container .item .title {
    font-weight: 600;
    font-size: 18px;
    line-height: 27px;
    color: var(--dark-4);
    padding-top: 15px;
}

.card-list-container .item .desc {
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    color: var(--light-gray-4);
    padding-top: 8px;
}

.dark .event-title,
.dark .card-list-container .item .title {
    color: white;
}

.dark .card-list-container .item .sub-title{
    color: var(--light-gray);
}
.dark .card-list-container .item .desc {
    color: var(--light-gray-4);
}

.skeleton-box {
    display: inline-block;
    height: 1em;
    position: relative;
    overflow: hidden;
    background-color: #eeecf1;
}
.skeleton-box::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    transform: translateX(-100%);
    background-image: linear-gradient(
        90deg,
        rgba(255, 255, 255, 0) 0,
        rgba(255, 255, 255, 0.2) 20%,
        rgba(255, 255, 255, 0.5) 60%,
        rgba(255, 255, 255, 0)
    );
    -webkit-animation: shimmer 1.5s infinite;
    animation: shimmer 1.5s infinite;
    content: '';
}
@-webkit-keyframes shimmer {
    50% {
        transform: translateX(100%);
    }
}
@keyframes shimmer {
    50% {
        transform: translateX(100%);
    }
}

.skeleton__headline {
    font-size: 1.25em;
    font-weight: bold;
}
.blog-post__meta {
    font-size: 0.85em;
    color: #6b6b6b;
}

@media (max-width: 775px) { 
    .card-list-container {
        gap: 10px;
        padding-top: 0px;
    }

    .card-list-container .item {
        width: 235px;
        height: 100%;
    }
}


/* .o-media {
    display: flex;
}
.o-media__body {
    flex-grow: 1;
    margin-left: 1em;
}

.o-vertical-spacing > * + * {
    margin-top: 0.75em;
}
.o-vertical-spacing--l > * + * {
    margin-top: 2em;
} */
