.headerMenuContainer .ant-menu-submenu-arrow {
    display: none;
}

.headerMenuContainer {

}

/* disabled selected active effect */
.headerMenuContainer .ant-menu-vertical .ant-menu-submenu-selected, .ant-menu-vertical-left .ant-menu-submenu-selected, .ant-menu-vertical-right .ant-menu-submenu-selected,
.ant-menu-vertical .ant-menu-submenu-selected, .ant-menu-vertical-left .ant-menu-submenu-selected, .ant-menu-vertical-right .ant-menu-submenu-selected {
    color:unset;
}