.portfolio-container > .row > .title {
    font-weight: 900;
    font-size: 24px;
    line-height: 36px;
    color: var(--dark-4);
    padding-bottom: 16px;
}

.portfolio-container .search-box-container {
    display: flex;
    /* justify-content: center; */
}

.portfolio-slt-container {
    display: flex;
    flex-wrap: wrap;
    gap: 32px;
}

.portfolio-slt-container .item {
    display: flex;
    align-items: center;
}

.portfolio-slt-container .item .label {
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    color: var(--dark-4);
    padding-right: 20px;
}

.info-box-container {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    gap: 24px;
}

.info-box-container > .item {
    width: 100%;
    /* max-width: 227.43px; */
    height: 62px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    background: var(--light-gray-3);
    border-radius: 12px;
}

.info-box-container > .item.fullwidth {
    width: 100%;
    /* max-width: 480px; */
}

.info-box-container > .item img {
    padding: 12px 15px;
}

.info-box-container > .item .title {
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    color: var(--dark-4);
}

.info-box-container > .item .value {
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    color: var(--light-gray-4);
}

.info-row-container {
    display: flex;
    flex-wrap: wrap;
    column-gap: 57px;
    row-gap: 20px;
}

.info-row-container > .item {
    /* width: 100%; */
    height: 100%;
}

.info-row-container > .item > .title {
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    color: var(--dark-4);
}

.info-row-container > .item > .value {
    font-weight: 700;
    font-size: 24px;
    line-height: 36px;
    color: var(--dark-4);
}

.portfolio-card-container .card-block {
    color: var(--light-gray);
    background: var(--light-gray-3);
}

.portfolio-card-container .card-block {
    width: 227.43px;
    height: 146px;
    display: flex;
    text-align: left;
    flex-direction: column;
    align-items: flex-start;
    flex-wrap: wrap;
    justify-content: space-evenly;
    padding-left: 20px;
    padding-right: 20px;
    gap: 10px;
    border-radius: 12px;
}

.portfolio-card-container .card-block .title {
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
}

.portfolio-card-container .card-block .currency {
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
}

.portfolio-card-container .card-block .value {
    font-weight: 700;
    font-size: 24px;
    line-height: 42px;
}

.portfolio-card-container .card-block.active {
    background: var(--blue-1);
    color: white;
}

.portfolio-card-container .card-block .title,
.portfolio-card-container .card-block .currency {
    color: var(--dark-4);
}

.portfolio-card-container .card-block .value {
    color: var(--light-gray-4);
}

.portfolio-card-container .card-block.active .title,
.portfolio-card-container .card-block.active .currency,
.portfolio-card-container .card-block.active .value {
    color: white;
}

.custom-nav-pills > .nav-item > button {
    border: none;
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    color: var(--light-gray-4);
    background: var(--light-gray-3);
    padding: 5px 12px;
}

.custom-nav-pills > .nav-item > button.active {
    background: #ffffff;
    /* /shadow/base */
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.1), 0px 1px 2px rgba(0, 0, 0, 0.06);
    border-radius: 6px;
    color: var(--dark-4);
}

.custom-nav-pills {
    width: fit-content;
    background: var(--light-gray-3);
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 2px;
    transition: 1.3s;
    border-radius: 6px;
}

.report-table-container {
    width: 100%;
    /* max-width: 479px; */
    height: 100%;

    /* Light Grey 02 */
    background: var(--light-gray-3);
    border-radius: 12px;

    padding: 5px;
}

.report-table-container > .row {
    padding-top: 5px;
    padding-bottom: 5px;
    border-bottom: 1px solid #cbd5e1;
}

.report-table-container > .row:last-child {
    border: none;
}

.report-table-container > .row > .label {
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    text-align: left;
    color: var(--dark-4);
    padding-top: 4px;
}

.report-table-container > .row > .value {
    text-align: right;
    font-weight: 700;
    font-size: 20px;
    line-height: 30px;
    color: var(--light-gray-4);
}

.report-table-container > .row > .value.red {
    color: #eb5533;
}

.portfolio-container .fixed-height-container {
    height: 70vh;
    position: relative;
    overflow: auto;
}

.dark .report-table-container {
    background: var(--dark-2);
}

.dark .report-table-container > .row > .label {
    color: var(--light-gray);
}

.dark .info-box-container > .item {
    background: var(--dark-2);
}

.dark .info-box-container > .item .title {
    color: var(--light-gray);
}

.dark .info-box-container > .item svg {
    color: var(--light-gray);
}

.dark .chart-container {
    background-color: var(--dark-2);
}

@media (min-width: 1500px) {
    .info-box-container {
        gap: 5px;
    }

    .info-box-container > .item {
        width: 49%;
        /* max-width: 190px; */
    }
}

@media (max-width: 1000px) {
    .portfolio-container .fixed-height-container {
        height: 100%;
        overflow: unset;
    }
}
