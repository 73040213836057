.react-table {
    border-spacing: 10px;
    border-collapse: collapse;
    /* border: 1px solid black; */
    width: 100%;
}

.react-table > thead {
    background-color: #3268f6;
    color: white;
}

.react-table > thead > tr > th {
    padding: 13px 20px;
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
}

.react-table > thead > tr > th:first-child {
    border-radius: 12px 0px 0px 0px;
}

.react-table > thead > tr > th:last-child {
    border-radius: 0px 12px 0px 0px;
}
.react-table > thead > tr > th:only-child {
    border-radius: 12px 12px 0px 0px;
}

.react-table > tbody > tr {
    border-bottom: 1px solid var(--light-gray);
}

.react-table > tbody > tr > td {
    margin: 0;
    padding: 13px 20px;
    font-weight: 400;
    font-size: var(--font-size-md);
    line-height: 21px;
    color: #2b2e38;
}

.react-table > tbody > tr:hover {
    background-color: #d8f1ff;
}

.react-table > tbody > tr.selected {
    background-color: #d8f1ff;
}

.react-table > tbody > tr button {
    font-size: 14px;
    width: 100px;
}

.react-table .cursor-pointer {
    cursor: pointer;
}

.react-table-container {
    /* background: rgb(246, 245, 245); */
}

.react-table-container .no-data {
    /* display: flex;
    justify-content: center;
    align-items: center; */
    text-align: center;
    width: 100%;
    height: 200px;
    background: rgb(246, 245, 245);
    border: 0px solid #fff;
    color: #bfc0c5;
    font-size: 17px;
}

.pagination-container {
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding: 10px;
}

.pagination-container .page-info {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 6px;
}

.pagination-container .pagination-list {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 6px;
}

.react-table-container .pagination-btn {
    padding: 6px 12px;
    gap: 6px;

    width: auto;
    height: 36px;

    background: #a7aec2;
    border-radius: 8px;
    color: white;
    cursor: pointer;
}

.react-table-container .previous-btn,
.react-table-container .next-btn {
    border: none;
    background-color: inherit;

    font-weight: 400;
    font-size: 14px;
    line-height: 21px;

    color: #2b2e38;
}

.react-table-container .pagination-btn.current {
    background: #4f596c;
}

/* /////////////// */
/* dark theme */
/* /////////////// */

.dark .react-table > tbody > tr > td {
    background: var(--dark-2);
    color: var(--light-gray);
}

.dark .react-table > tbody > tr {
    border-bottom: 1px solid var(--light-gray-4);
}

.dark .pagination-container {
    background-color: var(--light-gray-4);
    color: var(--light-gray);
}

.ant-pagination-item-active {
    background-color: #4f596c!important;
    color: white;
    border-color: unset!important;
}

.ant-pagination-item,.ant-pagination-next .ant-pagination-item-link, .ant-pagination-prev .ant-pagination-item-link {
    border-radius: 8px!important;
}
