.MyDatePicker {
    width: 100%;
    float: left;
    position: relative;
}
.MyDatePicker * {
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Chrome/Safari/Opera */
    -khtml-user-select: none; /* Konqueror */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none; /* Non-prefixed version, currently */
}

.MyDatePicker .mdp-input {
    position: sticky;
    float: left;
    width: 100%;
    /* width: 227.43px; */
    height: 44px;
    overflow: hidden;

    background: #ffffff;
    /* Light Grey 02 */

    border: 1px solid #c1c6cf;
    border-radius: 8px;

    /* border-radius: 20px; */
}
.MyDatePicker .mdp-input input:focus {
    outline: none;
}
.MyDatePicker .mdp-input input {
    padding-left: 10px;
    width: 100%;
    /* background: #f5f5f5; */
    border: none;
    /* padding-right: 10px; */
    height: 100%;
    text-align: left;
    /* text-transform: uppercase; */
    /* letter-spacing: 2px; */
    font-size: 14px;
    background: #ffffff;
    cursor: pointer;

    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    color: #2b2e38;
}
.MyDatePicker .mdp-input input:disabled {
    background: none;
}

.MyDatePicker .mdp-input > i {
    position: absolute;
    width: 16px;
    height: 16px;
    right: 16px;
    top: 35%;
    color: var(--dark-4);
    font-size: 14px;
}

.MyDatePicker .mdp-container {
    float: left;
    position: absolute;
    left: 0;
    top: 40px;
    width: 300px;
    min-height: 350px;
    background: #fff;
    /* box-shadow: 10px 10px 40px rgba(0, 0, 0, 0.2);
    border-radius: 20px; */
    overflow: hidden;
    padding: 20px 20px;
    z-index: 999;

    /* Basic/White */

    background: #ffffff;
    /* Light Grey 02 */

    border: 1px solid #c1c6cf;
    /* /shadow/lg */

    box-shadow: 0px 10px 15px -3px rgba(0, 0, 0, 0.1),
        0px 4px 6px -2px rgba(0, 0, 0, 0.05);
    border-radius: 8px;
    opacity: 0;
    visibility: hidden;
}

.MyDatePicker .mdp-container.show {
    visibility: visible;
    opacity: 1;
    transition: opacity 0.1s linear;
}
.MyDatePicker .mdpc-head {
    float: left;
    width: 100%;
    height: 53px;

    display: flex;
    justify-content: space-between;
    align-items: center;
}
.MyDatePicker .mdpc-body {
    float: left;
    width: 100%;
    margin-top: 20px;
}

/**
 * Controls
 */

.MyDatePicker .mdpch-button {
    float: left;
    width: 45px;
    height: 100%;
    box-sizing: border-box;
    position: relative;
}
.MyDatePicker .mdpchb-inner:hover > span {
    border-color: #555 !important;
}
.MyDatePicker .mdpchb-inner:hover {
    cursor: pointer;
    /* background: #eee; */
    /* background: #18cf42; */
}
.MyDatePicker .mdpchb-inner {
    float: left;
    height: 45px;
    width: 35px;
    /* background: #f4f4f4; */
    border-radius: 100%;
    line-height: 35px;
    text-align: center;
    position: absolute;
    top: 50%;
    left: 50%;
    margin-left: -17px;
    margin-top: -19px;
}

.MyDatePicker .mdpchbi-right-arrows:after,
.MyDatePicker .mdpchbi-left-arrows:after,
.MyDatePicker .mdpchbi-right-arrow,
.MyDatePicker .mdpchbi-right-arrows,
.MyDatePicker .mdpchbi-left-arrow,
.MyDatePicker .mdpchbi-left-arrows {
    display: block;
    float: left;
    width: 9px;
    height: 9px;
    border-left: 2px solid #888;
    border-bottom: 2px solid #888;
    position: absolute;
}
.MyDatePicker .mdpchbi-right-arrow,
.MyDatePicker .mdpchbi-right-arrows,
.MyDatePicker .mdpchbi-left-arrow,
.MyDatePicker .mdpchbi-left-arrows {
    transform: rotate(45deg);
    left: 50%;
    top: 50%;
    margin-left: -2px;
    margin-top: -4px;
}
.MyDatePicker .mdpchbi-right-arrows,
.MyDatePicker .mdpchbi-right-arrow {
    transform: rotate(225deg);
    margin-left: -4px;
}
.MyDatePicker .mdpchbi-right-arrows:after,
.MyDatePicker .mdpchbi-left-arrows:after {
    content: '';
}

.MyDatePicker .mdpchbi-left-arrows {
    margin-left: -5px;
}
.MyDatePicker .mdpchbi-right-arrows {
    margin-left: -2px;
}
.MyDatePicker .mdpchbi-right-arrows:after,
.MyDatePicker .mdpchbi-left-arrows:after {
    left: 3px;
    top: -5px;
}

.MyDatePicker .mdpch-container {
    float: left;
    width: 120px;
    height: 100%;

    display: flex;
    align-items: center;
}
.MyDatePicker .mdpchc-year {
    float: left;
    width: 100%;
    height: 30px;
    font-size: 27px;
    color: #666;
    font-weight: 200px;
    text-align: center;
}
.MyDatePicker .mdpchc-month {
    float: left;
    width: 100%;
    height: 15px;

    text-align: center;

    font-weight: 600;
    font-size: 14px;
    line-height: 21px;
    text-align: center;
    font-feature-settings: 'pnum' on, 'lnum' on;

    /* Neutral/900 */

    color: #0f172a;
}

/**
 *  Calendar
 */
.cc-month,
.cc-head,
.cch-name,
.cc-body,
.cdc-day span,
.cdc-day,
.c-day-container,
.c-container {
    position: relative;
    display: block;
    float: left;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}

.c-container {
    width: 100%;
    height: 100%;
}

.cc-month {
    height: 30px;
    width: 100%;
    font-size: 16px;
    line-height: 30px;
    color: #666;
}
.cc-head {
    height: 30px;
    width: 100%;
    margin-top: 10px;
}
.cch-name {
    width: 14.285%;
    height: 30px;
    line-height: 30px;
    font-weight: 700;
    color: #666;
    font-size: 9px;
    text-align: center;
}
.cc-body {
    height: 270px;
    width: 100%;
}
.c-day-container {
    width: 14.285%;
    height: 16.6666%;
}
.cdc-day {
    width: 100%;
    height: 100%;
    font-size: 12px;
    font-weight: 300;
    color: #444;
    text-align: center;
}
.cdc-day span {
    width: 100%;
    height: 100%;
    font-size: 12px;
    font-weight: 300;
    color: #444;
}

.cdc-day span:hover {
    cursor: pointer;
    background: #eee;
}
.cdc-day span {
    width: 30px;
    height: 30px;
    margin-top: -15px;
    margin-left: -15px;
    left: 50%;
    top: 50%;
    font-weight: 400;
    border-radius: 100%;
    line-height: 30px;
}
.c-day-container.disabled {
    pointer-events: none;
}

.c-day-container.disabled .cdc-day span {
    color: #7a8599;
}
.c-day-container.disabled .cdc-day span {
    background: #fff !important;
}
.c-day-container.highlight .cdc-day span {
    background: #efdbca;
}
.c-day-container.highlight-green .cdc-day span {
    background: rgba(50, 104, 246, 0.25);
    border: 1px solid #3268f6;
    border-radius: 24px;
}

.c-day-container.highlight-green.disabled .cdc-day span {
    border: none;
}

.mdp-input > input::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: inherit;
    opacity: 1; /* Firefox */
}

.mdp-input > input::-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: inherit;
}

.mdp-input > input::-ms-input-placeholder {
    /* Microsoft Edge */
    color: inherit;
}

.dark .MyDatePicker .mdp-input {
    background: var(--dark-5);
}

.dark .MyDatePicker .mdp-input input {
    color: white;
}

@media only screen and (max-width: 1000px) {
    .mdp-input {
        width: 100%;
        /* max-width: 227.43px; */
    }
}
